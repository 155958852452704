import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import VueCookies from 'vue-cookies'
import { bpoTip } from '@/scripts/bpo.js';
import store from '@/store/index.js'
import {
    getStorage,
    setStorage,
    removeStorage,
    FormatTime,
    isJsonString,
    getQueryString,
    setCompanyCookies,
    setPersonCookies
} from '@/scripts/util.js'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import Password from '@/components/password/password.js';

// 聊天模块的js引入
import WebIM from '@/scripts/WebIM.js';
Vue.use(WebIM)
Vue.prototype.$IM = WebIM;
Vue.prototype.$query = getQueryString;



// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';


Vue.component(CollapseTransition.name, CollapseTransition)




Vue.use(preview, {
    fullscreenEl: false //关闭全屏幕展示
});




import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
//引入 hls,视频直播(m3u8)必须引入的
// import 'videojs-contrib-hls'
//播放rtmp视频
// import 'videojs-flash'
//如果你需要自定义播放器的样式，自己新建一个css
// require('./assets/css/video_css/myvideo.css')
Vue.use(VideoPlayer)


import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.prototype.$toast = bpoTip;
Vue.prototype.$setStorage = setStorage;
Vue.prototype.$getStorage = getStorage;
Vue.prototype.$removeStorage = removeStorage;
Vue.prototype.$formatTime = FormatTime;
Vue.prototype.$password = Password;
Vue.prototype.$isJsonString = isJsonString;
Vue.prototype.$setCompanyCookies = setCompanyCookies;
Vue.prototype.$setPersonCookies = setPersonCookies;


//格式化价格
Vue.filter('formatPrice', function(value) {
        var num = 0;
        if (!isNaN(Number(value))) {
            num = Number(value);
        }
        return num.toFixed(2);
    }) //格式化价格
Vue.filter('formatTime', FormatTime)
import versionTood from '@/libs/versionUpdate'    //清除浏览器缓存
router.beforeEach((to, from, next) => {
    // console.log('-----------------------',to, from);
    versionTood.isNewVersion();       ////判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
    if (to.matched.some(res => res.meta.isLogin)) {
        // console.log(store.state.userInfo);
        //判断是否需要登录
        // console.log(store.state.userInfo);
        if (store.state.userInfo) {
            next();
        } else {
            next({
                path: "/companyLogin",
            });
        }
    } else {
        next()
    }
});


new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')