import axios from 'axios';
import Qs from 'qs'
import $ from 'jquery';
window.$ = $;
import { BASEURL, personUrl, PersonLogin } from '@/scripts/util.js';
import { bpoLoading, bpoHideLoading, bpoTip } from '@/scripts/bpo.js';
let isIE9 = navigator.userAgent.indexOf('MSIE 9.0') >= 0 ? true : false;

function Request(method = 'post', url, params, loading) {
    var loadingLayer;
    var isLoading = typeof(loading)=='undefined'?true:loading;
    console.log("request.js Request-isLoading:"+isLoading);
    if (isLoading) {
        loadingLayer = bpoLoading();
    }
    method = method.toUpperCase();
    let _url = url;
    if (!params) {
        params = { platform: 'pcweb' };
    } else {
        params['platform'] = 'pcweb';
    }
    if (method == 'POST' && params) {
        params = JSON.stringify(params);
    }
    if (method == 'POST' && params && _url.indexOf('/ai') !== -1) {
        //ocr识别传x-www-form-urlencoded数据
        let obj = JSON.parse(params);
        let str = '';
        for (const k in obj) {
            str += k + '=' + obj[k] + '&'
        }
        params = str.substring(0, str.lastIndexOf('&'));
    }
    let dataType = "json";
    if (_url.indexOf('api.map.baidu.com') !== -1) {
        dataType = 'jsonp'
    }
    //个人登录域名
    if (url && url.indexOf('/sso/') != -1) {
        _url = PersonLogin + url;
    } else if (url.indexOf('http') === -1) {
        _url = BASEURL + url;
    }
    return new Promise(function(resolve, reject) {
        $.ajax({
            type: method,
            url: _url,
            dataType: dataType,
            beforeSend: function(req) {
                if (_url.indexOf('.market.alicloudapi.com') !== -1) {
                    //ocr识别接口
                    req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
                    req.setRequestHeader("Authorization", "APPCODE 981ec494f9ba46a69ee7af50d429c237");
                } else if (_url.indexOf('api.map.baidu.com') !== -1) {
                    //获取IP坐标
                    req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
                } else {
                    req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                }
            },
            crossDomain: true == !(document.all),
            // contentType: 'application/json;charset=UTF-8',
            data: params,
            success: function(data) {
                resolve(data);
            },
            error: function(err, status) {
                bpoTip('请求失败！');
                reject(err);
            },
            complete: function(res) {
                if (isLoading) {
                    bpoHideLoading(loadingLayer);
                }
            }
        });
    })
}

// function Request(method = 'post', url, params) {
//     axios.defaults.BASEURL = BASEURL;
//     axios.defaults.timeout = 15000;
//     axios.defaults.headers = { 'Content-Type': 'application/json;charset=UTF-8' };
//     return axios({
//         method: method,
//         url: url,
//         data: method == 'post' ? params : null,
//         params: method == 'get' ? params : null,
//     });
// }
// // request拦截器
// axios.interceptors.request.use((config) => {
//     return config;
// }, (error) => {
//     bpoTip('请求失败！');
//     return Promise.reject(error);
// });

// // respone拦截器
// axios.interceptors.response.use((response) => {
//     return response.data;
// }, (error) => {
//     if (error.message) {
//         // 登录超时
//         if (error.message === 'Network Error') {
//             bpoTip('请求失败！');
//         }
//     }
//     return Promise.reject(error);
// });

export default Request;