var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"fixedRight"}},[_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_c('li',{staticClass:"item-nav"},[_c('div',{staticClass:"wrap-hover",on:{"click":_vm.jumpChatContent}},[_vm._m(3),_vm._m(4)])]),_c('li',{class:['item-nav return-top',{'active':_vm.showTop}],on:{"click":_vm.backTop}},[_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item-nav"},[_c('div',{staticClass:"wrap-hover"},[_c('a',{staticClass:"a-style icon-spAPP",attrs:{"href":"javascript: void(0);"}},[_vm._v("速派"),_c('br'),_vm._v("APP")]),_c('div',{staticClass:"ptn-add ptn-top2"},[_c('span',[_c('img',{attrs:{"src":require("../assets/fixedRight/supaiapp_ercode.png"),"alt":""}})]),_c('div',[_c('p',[_vm._v("工业速派")]),_c('p',[_vm._v("扫码下载APP")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item-nav"},[_c('div',{staticClass:"wrap-hover"},[_c('a',{staticClass:"a-style icon-wxgzh",attrs:{"href":"javascript: void(0);"}},[_vm._v("微信"),_c('br'),_vm._v("公众号")]),_c('div',{staticClass:"ptn-add ptn-top2"},[_c('span',[_c('img',{attrs:{"src":require("../assets/fixedRight/supaiweixin_ercode.jpg"),"alt":""}})]),_c('div',[_c('p',[_vm._v("工业速派")]),_c('p',[_vm._v("微信公众号")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item-nav"},[_c('div',{staticClass:"wrap-hover"},[_c('a',{staticClass:"a-style icon-kfphone",attrs:{"href":"javascript: void(0);"}},[_vm._v("客服"),_c('br'),_vm._v("电话")]),_c('div',{staticClass:"ptn-add ptn-top3"},[_c('a',{attrs:{"href":"javascript: void(0);"}},[_vm._v("客服电话：021-60956592")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"a-style icon-onlinekf",attrs:{"href":"javascript: void(0);"}},[_vm._v("速派"),_c('br'),_vm._v("客服")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ptn-add ptn-top3"},[_c('a',{attrs:{"href":"javascript: void(0);"}},[_vm._v("速派客服，在线聊天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap-hover"},[_c('a',{staticClass:"a-style icon-fhdb",attrs:{"href":"javascript: void(0);"}},[_vm._v("返回"),_c('br'),_vm._v("顶部")])])
}]

export { render, staticRenderFns }