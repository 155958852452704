<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <fixed-right v-if="!hideFixedRight"></fixed-right>
  </div>
</template>

<script>
import FixedRight from '@/components/fixedRight'
import { mapActions } from 'vuex'
// import Bus from '@/scripts/bus.js'
export default {
  data() {
    return {
      hideFixedRight: true
    }
  },
  name: 'App',
  components:{
    FixedRight,
  },
  watch: {
    '$route'(newVal, oldVal) {
      // h5的页面
      if(this.$route.path !== '/h5/buyVipMember' && this.$route.path !== '/h5/luckyGrid') {
        setTimeout(() => {
          this.hideFixedRight = false
        }, 500);
      }
    }
  },
  created() {
    this.getSecondProductList();
  },
  methods: {
    ...mapActions([
      'getSecondProductList',
    ]),
  }
}
</script>

<style>
@import '~@/style/reset.scss'
</style>
