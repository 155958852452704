import axios from 'axios'
 
const isNewVersion = () => {
  let url = ''
  if(process.env.NODE_ENV == 'development') {
      url = `//${window.location.host}/static/version.json?t=${new Date().getTime()}`;
  }else {
      url = `//${window.location.host}/site/static/version.json?t=${new Date().getTime()}`;
  }
  axios.get(url).then(res => {
    if (res.status === 200) {
      let vueVersion = res.data.version;
      let localVueVersion = localStorage.getItem('vueVersion');
      // let localVueVersion = undefined;
      console.log(vueVersion)
      // console.log(localVueVersion)
      // console.log(!!localVueVersion && localVueVersion != vueVersion)
      if (localVueVersion && localVueVersion != vueVersion) {
        localStorage.setItem('vueVersion', vueVersion);
        // console.log(localStorage.getItem('vueVersion'))
        // console.log("-------------------------------","刷新")
        window.location.reload();
        return;
      } else {
        localStorage.setItem('vueVersion', vueVersion);
      }
    }
  });
}
 
export default {
  isNewVersion
}