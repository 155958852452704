<template>
  <div id="fixedRight">
    <ul>
			<!-- <li class="item-nav">
				<div class="wrap-hover">
					<a href="javascript: void(0);" class="a-style icon-QQkf">QQ<br>客服</a>
					<div class="ptn-add ptn-top">
						<i></i>
						<a href="http://wpa.qq.com/msgrd?v=3&uin=3502607950&site=qq&menu=yes" target="_blank">QQ客服速小派</a>
						<a href="http://wpa.qq.com/msgrd?v=3&uin=1451232104&site=qq&menu=yes" target="_blank">QQ客服西门子考核</a>
					</div>
				</div>
			</li> -->
			<li class="item-nav">
				<div class="wrap-hover">
					<a href="javascript: void(0);" class="a-style icon-spAPP">速派<br>APP</a>
					<div class="ptn-add ptn-top2">
						<span>
							<img src="../assets/fixedRight/supaiapp_ercode.png" alt="" />
						</span>
						<div>
							<p>工业速派</p>
							<p>扫码下载APP</p>
						</div>
					</div>
				</div>
			</li>
			<li class="item-nav">
				<div class="wrap-hover">
					<a href="javascript: void(0);" class="a-style icon-wxgzh">微信<br>公众号</a>
					<div class="ptn-add ptn-top2">
						<span>
							<img src="../assets/fixedRight/supaiweixin_ercode.jpg" alt="" />
						</span>
						<div>
							<p>工业速派</p>
							<p>微信公众号</p>
						</div>
					</div>
				</div>
			</li>
			<li class="item-nav">
				<div class="wrap-hover">
					<a href="javascript: void(0);" class="a-style icon-kfphone">客服<br>电话</a>
					<div class="ptn-add ptn-top3">
						<a href="javascript: void(0);">客服电话：021-60956592</a>
					</div>
				</div>
			</li>
			<!-- <li class="item-nav">
				<div class="wrap-hover">
					<a :href="helpCenter" class="a-style icon-bzzx">帮助中心</a>
					<div class="ptn-add ptn-top3">
						<a :href="helpCenter">速派帮助中心，为您排忧解惑</a>
					</div>
				</div>
			</li> -->
			<li class="item-nav">
				<div class="wrap-hover" @click="jumpChatContent">
					<a  href="javascript: void(0);" class="a-style icon-onlinekf">速派<br>客服</a>
					<div class="ptn-add ptn-top3">
						<a  href="javascript: void(0);">速派客服，在线聊天</a>
					</div>
				</div>
			</li>
			<li :class="['item-nav return-top',{'active':showTop}]" @click="backTop">
				<div class="wrap-hover">
					<a href="javascript: void(0);" class="a-style icon-fhdb">返回<br>顶部</a>
				</div>
			</li>
		</ul>
  </div>
</template>
<script>
import {p_origin} from '@/scripts/util.js'
import { AddTourist } from "@/scripts/api.js"
import { mapActions } from "vuex";
export default {
  data(){
    return{
      showTop:false,
      helpCenter:p_origin+'/Help/helpCenter_01.html'
    }
  },
  mounted() {
    this.docScroll();
  },
  methods:{
		...mapActions(['onLogin']),
    docScroll(){
      document.onscroll=(e)=> {
        let top=document.documentElement.scrollTop;
        if(top>100){
          this.showTop=true;
        }else{
          this.showTop=false;
        }
      }
    },
    backTop(){
      window.scrollTo({
          top:0,
          behavior:'smooth'
      })
    },
	generateMixed(n) {
		var chars = ['0','1','2','3','4','5','6','7','8','9',
					'a','b','b','d','e','f','g','h','i','j','k','l','m',
					'n','o','p','q','r','s','t','u','v','w','x','y','z'];
		var res = "";
		for(var i = 0; i < n ; i++) {
			var id = Math.floor(Math.random()*36);
			res += chars[id];
		}
		return res;
	},
	jumpChatContent() {
		// 如果是已经登录的话 然后再点击这里的客服 那么就是直接跳到客服页面
		if(this.$store.state.userInfo) {
			console.log("11");
			this.$router.push({
				path: '/chat/chatContent',
				query: {
					activeKey: 'contact',
				}
			})
			return ;
		}
		let randomUserCode =  `temp_${this.generateMixed(6)}`
		console.log("randomUserCode",randomUserCode);
		AddTourist( {
			SendUserCode: randomUserCode,
			platform: "pcweb"
		}).then(res=>{
			if(res.Result == 1) {
				let  username = '';
				// 生成随机游客的账号也是有区分本地环境和线上环境的
				if(process.env.NODE_ENV == 'development') {
					username = `qa-${randomUserCode}`
				}else if(process.env.NODE_ENV == 'text'){
					username = `sta-${randomUserCode}`
				}else {
					username = `pro-${randomUserCode}`
				}
				this.onLogin({
					username: username,
					password: "supai2020"
				});
				setTimeout(() => {
					localStorage.setItem('randomUserInfo', JSON.stringify({"TrueName": "游客","UserCode": username,"UserId": username}));
					localStorage.setItem('randomHxChatList', JSON.stringify([res.Data]));
					this.$router.push({
						path: '/chat/chatContent',
						query: {
							activeKey: 'contact',
							chatType: 'random'
						}
					})
				}, 200);
			}
			console.log("res",res);
		})
		
	}
  },
  destroyed() {
    document.onscroll=null;
  },
}
</script>
<style lang="scss">
#fixedRight{
  right: 20px;
  position: fixed;
  top: 36%;
  z-index: 2;
	.item-nav{
		&:first-child{
			.a-style{
				border-top: 0;
			}
		}
	}
  .wrap-hover {
		position: relative;
		background: #666;
		.a-style {
			width: 54px;
			height: 54px;
			display: block;
			background-position: center center;
			background-size: 70%;
			background-repeat: no-repeat;
			border-top: 1px solid #fff;
			font-size: 14px;
			color: #fff;
			line-height: 16px;
			text-align: center;
			padding-top: 11px;
		}
  }
  // .icon-QQkf {
  //     background-image: url('~@/assets/fixedRight/fixed_1_kf.png');
  // }

  // .icon-spAPP {
  //     background-image: url('~@/assets/fixedRight/fixed_2_app.png');
  // }

  // .icon-wxgzh {
  //     background-image: url('~@/assets/fixedRight/fixed_3_gzh.png');
  // }

  // .icon-kfphone {
  //     background-image: url('~@/assets/fixedRight/fixed_4_dh.png');
  // }

  // .icon-bzzx {
  //     background-image: url('~@/assets/fixedRight/fixed_6_bz.png');
  // }
  // .icon-onlinekf {
  //     background-image: url('~@/assets/fixedRight/fixed_7_kf.png');
  // }

  // .icon-fhdb {
  //     background-image: url('~@/assets/fixedRight/fixed_5_db.png');
  // }

  .ptn-add {
      display: block;
      position: absolute;
      right: 54px;
      min-width: 150px;
      display: none;
      top: 1px;
  }

  .ptn-top {
		top: -89px;
		&>a {
			display: block;
			width: 100%;
			height: 53px;
			display: block;
			text-align: center;
			background-color: #f75959;
			color: #fff;
			line-height: 54px;
			white-space: nowrap;
			text-align: right;
			padding-left: 15px;
			padding-right: 15px;
		}
		a {
      text-align: center;
      margin-bottom: 1px;
		}
		i {
				display: block;
				width: 82px;
				height: 100px;
				background-image: url('~@/assets/fixedRight/su_xiao_pai.png');
				margin: 0 auto;
				margin-bottom: -10px;
		}
  }

  .ptn-top2 {
		padding: 20px;
		background-color: #f75959;
		span {
			display: block;
			width: 85px;
			height: 85px;
			margin: 0 auto;
			img {
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
		div {
      padding-top: 10px;
			p {
				margin-bottom: 0;
				color: #fff;
				text-align: center;
				font-size: 12px;
				line-height: 22px;
			}
		}
  }

  .ptn-top3>a {
      display: block;
      width: 100%;
      height: 53px;
      display: block;
      text-align: right;
      background-color: #f75959;
      color: #fff;
      line-height: 54px;
      white-space: nowrap;
      text-align: right;
      padding-left: 15px;
      padding-right: 15px;
  }

  .item-nav:hover .ptn-add {
      display: block !important;
  }

  .item-nav:hover .a-style {
      background-color: #f75959;
  }
  .return-top{
    opacity: 0;
    height: 0;
    transition: all 0.2s linear;
    overflow: hidden;
  }
  .return-top.active{
    height: 54px;
    opacity: 1;
  }


  /********新的右边悬浮栏end*************/

}
</style>