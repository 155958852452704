import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
export default new VueRouter({
    // mode: 'history',
    routes: [{
            path: '/',
            redirect: '/home'
        }, {
            path: '/home',
            name: 'Home',
            component: resolve => require(['@/pages/home/index.vue'], resolve)
        }, {
            path: '/register',
            name: 'Register',
            component: resolve => require(['@/pages/register/index.vue'], resolve)
        }, {
            path: '/companyRegister',
            name: 'CompanyRegister',
            component: resolve => require(['@/pages/register/companyRegister.vue'], resolve),
        }, {
            path: '/serviceRegister',
            name: 'ServiceRegister',
            component: resolve => require(['@/pages/register/serviceRegister.vue'], resolve),
        }, {
            path: '/personRegister',
            name: 'PersonRegister',
            component: resolve => require(['@/pages/register/personRegister.vue'], resolve)
        }, {
            path: '/personLogin',
            name: 'PersonLogin',
            component: resolve => require(['@/pages/login/personLogin.vue'], resolve)
        }, {
            path: '/companyLogin',
            name: 'CompanyLogin',
            component: resolve => require(['@/pages/login/companyLogin.vue'], resolve)
        }, {
            path: '/contract',
            name: 'Contract',
            component: resolve => require(['@/pages/contract/index.vue'], resolve)
        }, {
            path: '/baiduMap',
            name: 'BaiduMap',
            component: resolve => require(['@/pages/baiduMap/baiduMap.vue'], resolve)
        }, {
            path: '/center',
            name: 'Center',
            component: resolve => require(['@/pages/center/index.vue'], resolve),
            children: [{
                path: '/center/companyOverview',
                name: 'CompanyOverview',
                component: resolve => require(['@/pages/center/companyOverview.vue'], resolve),
            }, {
                path: '/center/personalOverview',
                name: 'PersonalOverview',
                component: resolve => require(['@/pages/center/personalOverview.vue'], resolve),
            }, {
                path: '/center/myCustomer',
                name: 'MyCustomer',
                component: resolve => require(['@/pages/center/customers.vue'], resolve),
            }, {
                path: '/center/filialeCustomer',
                name: 'FilialeCustomer',
                component: resolve => require(['@/pages/center/filialeCustomer.vue'], resolve),
            }, {
                path: '/center/activity',
                name: 'Activity',
                component: resolve => require(['@/pages/center/activity.vue'], resolve),
            }, {
                path: '/center/customerOrder',
                name: 'CustomerOrder',
                component: resolve => require(['@/pages/center/customerOrder.vue'], resolve),
            }, {
                path: '/center/filialeCustomerOrder',
                name: 'FilialeCustomerOrder',
                component: resolve => require(['@/pages/center/filialeCustomerOrder.vue'], resolve),
            }, {
                path: '/center/team',
                name: 'Team',
                component: resolve => require(['@/pages/center/team.vue'], resolve),
            }, {
                path: '/center/createdOrder',
                name: 'CreatedOrder',
                component: resolve => require(['@/pages/center/createdOrder.vue'], resolve),
            }, {
                path: '/center/undertakeOrder',
                name: 'UndertakeOrder',
                component: resolve => require(['@/pages/center/undertakeOrder.vue'], resolve),
            }, {
                path: '/center/filialeCreatedOrder',
                name: 'FilialeCreatedOrder',
                component: resolve => require(['@/pages/center/filialeCreatedOrder.vue'], resolve),
                meta: {
                    keepAlive: true,
                }
            }, {
                path: '/center/filialeUndertakeOrder',
                name: 'FilialeUndertakeOrder',
                component: resolve => require(['@/pages/center/filialeUndertakeOrder.vue'], resolve),
                meta: {
                    keepAlive: true,
                }
            }, {
                path: '/center/contract',
                name: 'CenterContract',
                component: resolve => require(['@/pages/center/contract.vue'], resolve),
            }, {
                path: '/center/companyRealName',
                name: 'CompanyRealName',
                component: resolve => require(['@/pages/center/companyRealName.vue'], resolve),
            }, {
                path: '/center/personalRealName',
                name: 'PersonalRealName',
                component: resolve => require(['@/pages/center/personalRealName.vue'], resolve),
            }, {
                path: '/center/discountCoupon',
                name: 'DiscountCoupon',
                component: resolve => require(['@/pages/center/discountCoupon.vue'], resolve),
            }, {
                path: '/center/couponRecord',
                name: 'CouponRecord',
                component: resolve => require(['@/pages/center/couponRecord.vue'], resolve),
            }, {
                path: '/center/suppliedCoupon',
                name: 'SuppliedCoupon',
                component: resolve => require(['@/pages/center/suppliedCoupon.vue'], resolve),
            }, {
                path: '/center/addressManage',
                name: 'AddressManage',
                component: resolve => require(['@/pages/center/addressManage.vue'], resolve),
            }, {
                path: '/center/serviceSiteInfo',
                name: 'ServiceSiteInfo',
                component: resolve => require(['@/pages/center/serviceSiteInfo.vue'], resolve),
            }, {
                path: '/center/serviceCertificate',
                name: 'ServiceCertificate',
                component: resolve => require(['@/pages/center/serviceCertificate.vue'], resolve),
            }, {
                path: '/center/orderCalendar',
                name: 'OrderCalendar',
                component: resolve => require(['@/pages/center/orderCalendar.vue'], resolve),
            }, {
                path: '/center/engineerCalendar',
                name: 'EngineerCalendar',
                component: resolve => require(['@/pages/center/engineerCalendar.vue'], resolve),
            }, {
                path: '/center/companyInfo',
                name: 'CompanyInfo',
                component: resolve => require(['@/pages/center/companyInfo.vue'], resolve),
            }, {
                path: '/center/personInfo',
                name: 'PersonInfo',
                component: resolve => require(['@/pages/center/personInfo.vue'], resolve),
            }, {
                path: '/center/accountProtect',
                name: 'AccountProtect',
                component: resolve => require(['@/pages/center/accountProtect.vue'], resolve),
            }, {
                path: '/center/invoice',
                name: 'Invoice',
                component: resolve => require(['@/pages/center/invoice.vue'], resolve),
            }, {
                path: '/center/balance',
                name: 'Balance',
                component: resolve => require(['@/pages/center/balance.vue'], resolve),
            }, {
                path: '/center/credit',
                name: 'Credit',
                component: resolve => require(['@/pages/center/credit.vue'], resolve),
            }, {
                path: '/center/receiveAccount',
                name: 'ReceiveAccount',
                component: resolve => require(['@/pages/center/receiveAccount.vue'], resolve),
            }, {
                path: '/center/myLesson',
                name: 'MyLesson',
                component: resolve => require(['@/pages/center/myLesson.vue'], resolve),
            }, {
                path: '/center/trainOrder',
                name: 'TrainOrder',
                component: resolve => require(['@/pages/center/trainOrder.vue'], resolve),
            }, {
                path: '/center/myExam',
                name: 'MyExam',
                component: resolve => require(['@/pages/center/myExam.vue'], resolve),
            }, {
                path: '/center/receivable',
                name: 'Receivable',
                component: resolve => require(['@/pages/center/receivable.vue'], resolve),
                meta: {
                    keepAlive: false,
                }
            },{
                path: '/center/buyVipMember',
                name: 'buyVipMember',
                component: resolve => require(['@/pages/center/buyVipMember.vue'], resolve),
            }, ]
        }, {
            path: '/orderList',
            name: 'OrderList',
            component: resolve => require(['@/pages/order/orderList.vue'], resolve),
            meta: {
                keepAlive: true,
            },
        }, {
            path: '/successCase',
            name: 'SuccessCase',
            component: resolve => require(['@/pages/order/successCase.vue'], resolve),
            meta: {
                keepAlive: true,
            },
        }, {
            path: '/recharge',
            name: 'Recharge',
            component: resolve => require(['@/pages/balance/recharge.vue'], resolve)
        }, {
            path: '/withdraw',
            name: 'Withdraw',
            component: resolve => require(['@/pages/balance/withdraw.vue'], resolve),
            meta: {
                isLogin: true,
            }
        }, {
            path: '/remittance',
            name: 'Remittance',
            component: resolve => require(['@/pages/balance/remittance.vue'], resolve)
        }, {
            path: '/afterWithdraw',
            name: 'AfterWithdraw',
            component: resolve => require(['@/pages/balance/afterWithdraw.vue'], resolve)
        }, {
            path: '/afterRecharge',
            name: 'AfterRecharge',
            component: resolve => require(['@/pages/balance/afterRecharge.vue'], resolve)
        }, {
            path: '/invoiceInfo',
            name: 'InvoiceInfo',
            component: resolve => require(['@/pages/balance/invoiceInfo.vue'], resolve)
        }, {
            path: '/createStep1',
            name: 'CreateStep1',
            component: resolve => require(['@/pages/createOrder/createStep1.vue'], resolve),
            meta: {
                isLogin: true,
                keepAlive: true,
            },
        }, {
            path: '/createStep2',
            name: 'CreateStep2',
            component: resolve => require(['@/pages/createOrder/createStep2.vue'], resolve),
            meta: {
                isLogin: true,
                keepAlive: true,
            }
        }, {
            path: '/createOrderProcess',
            name: 'CreateOrderProcess',
            component: resolve => require(['@/pages/createOrderProcess/index.vue'], resolve),
            children: [{
                path: '/createOrderProcess/orderInfo',
                name: 'OrderInfo',
                component: resolve => require(['@/pages/createOrderProcess/orderInfo.vue'], resolve),
            }, {
                path: '/createOrderProcess/bidder',
                name: 'Bidder',
                component: resolve => require(['@/pages/createOrderProcess/bidder.vue'], resolve),
            }, {
                path: '/createOrderProcess/costList',
                name: 'CostList',
                component: resolve => require(['@/pages/createOrderProcess/costList.vue'], resolve),
            }, {
                path: '/createOrderProcess/bill',
                name: 'Bill',
                component: resolve => require(['@/pages/createOrderProcess/bill.vue'], resolve),
            }, {
                path: '/createOrderProcess/server',
                name: 'Server',
                component: resolve => require(['@/pages/createOrderProcess/server.vue'], resolve),
            }, {
                path: '/createOrderProcess/serviceProcess',
                name: 'ServiceProcess',
                component: resolve => require(['@/pages/createOrderProcess/serviceProcess.vue'], resolve),
            }, {
                path: '/createOrderProcess/orderChange',
                name: 'OrderChange',
                component: resolve => require(['@/pages/createOrderProcess/orderChange.vue'], resolve),
            }, {
                path: '/createOrderProcess/serviceReport',
                name: 'ServiceReport',
                component: resolve => require(['@/pages/createOrderProcess/serviceReport.vue'], resolve),
            }, {
                path: '/createOrderProcess/thirdPartyReport',
                name: 'ThirdPartyReport',
                component: resolve => require(['@/pages/createOrderProcess/thirdPartyReport.vue'], resolve),
            }, {
                path: '/createOrderProcess/serviceEvaluation',
                name: 'ServiceEvaluation',
                component: resolve => require(['@/pages/createOrderProcess/serviceEvaluation.vue'], resolve),
            }, {
                path: '/createOrderProcess/projectProcess',
                name: 'ProjectProcess',
                component: resolve => require(['@/pages/createOrderProcess/projectProcess.vue'], resolve),
            }, {
                path: '/createOrderProcess/projectBill',
                name: 'ProjectBill',
                component: resolve => require(['@/pages/createOrderProcess/projectBill.vue'], resolve),
            },{
                path: '/createOrderProcess/marketCostList',
                name: 'MarketCostList',
                component: resolve => require(['@/pages/createOrderProcess/marketCostList.vue'], resolve),
            },  ]
        }, {
            path: '/pickTenderInfo',
            name: 'PickTenderInfo',
            component: resolve => require(['@/pages/pickOrder/pickTenderInfo.vue'], resolve),
            meta: {
                isLogin: true,
            }
        }, {
            path: '/pickFreeTenderInfo',
            name: 'PickFreeTenderInfo',
            component: resolve => require(['@/pages/pickOrder/pickFreeTenderInfo.vue'], resolve),
            meta: {
                isLogin: true,
            }
        }, {
            path: '/payment',
            name: 'Payment',
            component: resolve => require(['@/pages/balance/payment.vue'], resolve),
            meta: {
                isLogin: true,
            }
        }, {
            path: '/paySuccess',
            name: 'PaySuccess',
            component: resolve => require(['@/pages/balance/paySuccess.vue'], resolve),
            meta: {
                isLogin: true,
            }
        }, {
            path: '/rechargeSuccess',
            name: 'RechargeSuccess',
            component: resolve => require(['@/pages/balance/rechargeSuccess.vue'], resolve),
            meta: {
                isLogin: true,
            }
        },{
            path: '/resetPassword',
            name: 'ResetPassword',
            component: resolve => require(['@/pages/account/resetPassword.vue'], resolve)
        }, {
            path: '/lessonList',
            name: 'LessonList',
            component: resolve => require(['@/pages/lesson/lessonList.vue'], resolve),
            meta: {
                keepAlive: true,
            },
        }, {
            path: '/lessonDetail',
            name: 'LessonDetail',
            component: resolve => require(['@/pages/lesson/lessonDetail.vue'], resolve)
        }, {
            path: '/newsList',
            name: 'NewsList',
            component: resolve => require(['@/pages/news/newsList.vue'], resolve)
        }, {
            path: '/newsDetail',
            name: 'NewsDetail',
            component: resolve => require(['@/pages/news/newsDetail.vue'], resolve),
            meta: {
                // keepAlive: true,
            },
        },


        {
            path: '/newsEdit',
            name: 'NewsEdit',
            component: resolve => require(['@/pages/news/newsEdit.vue'], resolve)
        },
        {
            path: '/newsDrafts',
            name: 'NewsDrafts',
            component: resolve => require(['@/pages/news/newsDrafts.vue'], resolve)
        },
        
        
        
        
        
        
        
        
        
        {
            path: '/pickOrderInfo',
            name: 'PickOrderInfo',
            component: resolve => require(['@/pages/pickOrder/pickOrderInfo.vue'], resolve),
            meta: {
                isLogin: true,
            }
        }, {
            path: '/orderDetail',
            name: 'OrderDetail',
            component: resolve => require(['@/pages/pickOrder/orderDetail.vue'], resolve),
            meta: {
                isLogin: true,
            }
        }, {
            path: '/pickOrderProcess',
            name: 'PickOrderProcess',
            component: resolve => require(['@/pages/pickOrderProcess/index.vue'], resolve),
            meta: {
                isLogin: true,
            }
        },
        {
            path: '/chat',
            name: 'Chat',
            component: resolve => require(['@/pages/chat/index.vue'], resolve),
            children: [{
                path: '/chat/chatContent',
                name: 'ChatContent',
                component: resolve => require(['@/pages/chat/chatContent.vue'], resolve),
            }]
        },
        {
            path: '/productService',
            name: 'productService',
            component: resolve => require(['@/pages/service/productService.vue'], resolve),
        },
        {
            path: '/highEndService',
            name: 'HighEndService',
            component: resolve => require(['@/pages/service/highEndService.vue'], resolve),
        },
        {
            path: '/systemicService',
            name: 'SystemicService',
            component: resolve => require(['@/pages/service/systemicService.vue'], resolve),
        },
        {
            path: '/valueAddedService',
            name: 'ValueAddedService',
            component: resolve => require(['@/pages/service/valueAddedService.vue'], resolve),
        },
        {
            path: '/freeModule',
            name: 'FreeModule',
            component: resolve => require(['@/pages/platform/freeModule.vue'], resolve),
        },
        {
            path: '/b2bModule',
            name: 'B2bModule',
            component: resolve => require(['@/pages/platform/b2bModule.vue'], resolve),
        },
        {
            path: '/about',
            name: 'About',
            component: resolve => require(['@/pages/home/about.vue'], resolve),
        },
        {
            path: '/trainingArea',
            name: 'trainingArea',
            component: resolve => require(['@/pages/training/trainingArea.vue'], resolve),
        },
        {
            path: '/trainingDetail',
            name: 'trainingDetail',
            component: resolve => require(['@/pages/training/trainingDetail.vue'], resolve),
        },
        {
            path: '/trainingAll',
            name: 'trainingAll',
            component: resolve => require(['@/pages/training/trainingAll.vue'], resolve),
        },

        // 工业服务未来发展论坛
        {
            path: '/forumActivity',
            name: 'forumActivity',
            component: resolve => require(['@/pages/activity/forumActivity.vue'], resolve),
        },



        // 4.3服务页
        {
            path: '/servicePage',
            name: 'servicePage',
            component: resolve => require(['@/pages/service/servicePage.vue'], resolve),
        },
        // 支付宝支付成功后
        {
            path: '/allPaySuccess',
            name: 'allPaySuccess',
            component: resolve => require(['@/pages/balance/allPaySuccess.vue'], resolve),
        },
        {
            path: '/h5',
            name: 'h5',
            component: resolve => require(['@/pages/h5/buyVipMember.vue'], resolve),
            children: [{
                path: '/h5/buyVipMember',
                name: 'h5BuyVipMember',
                component: resolve => require(['@/pages/h5/buyVipMember.vue'], resolve),
            }]
        },
        // 支付宝支付成功后
        {
            path: '/h5/luckyGrid',
            name: 'h5LuckyGrid',
            component: resolve => require(['@/pages/h5/luckyGrid.vue'], resolve),
        },
    ]
}) 