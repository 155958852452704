/*******弹框封装***************/

import layer from 'layui-layer';

//alert提示
export function bpoAlert(msg, callback) {
    layer.alert(msg, {
        title: false, //false 是否显示头部；为"" 时显示头部但无标题
        closeBtn: false, //是否显示关闭按钮
        yes: function(index, layero) {
            if (callback != null && callback != undefined) {
                callback();
            }
            layer.close(index);
        }
    });
}

//确认框
export function bpoConfirm(msg, okFun) {
    layer.confirm(msg, {
        title: false,
        closeBtn: false,
        btn: ['确定', '取消'] //按钮
    }, function(index, layero) {
        //layer.msg('的确很重要', { icon: 1 });
        layer.close(index);
        if (okFun != null && okFun != undefined) {
            okFun();
        }
    });
}

//一个按钮自定义的确认弹框
export function bpoCustomConfirm(msg, btnText, btnFun, btnCancelText) {
    var txt = "取消";
    if (btnCancelText != "" && btnCancelText != null && btnCancelText != undefined) {
        txt = btnCancelText;
    }

    layer.confirm(msg, {
        title: false,
        closeBtn: false,
        btn: [btnText, txt] //按钮
    }, function(index, layero) {
        //layer.msg('的确很重要', { icon: 1 });
        //layer.close(index);
        if (btnFun != null && btnFun != undefined) {
            btnFun();
        }
    });
}

//黑色主题的提示框，然后1秒后关闭
export function bpoTip(msg) {
    layer.msg(msg, { time: 2000 });
}

//白色主题的提示框，2秒后消息
export function bpoTip2(msg, icon = 0) {
    layer.msg(msg, { time: 2000, skin: 'layui-bg-gray', icon });
}
//黑色主题的提示框，然后1秒后关闭
export function bpoTips(msg, el) {
    layer.tips(msg, el, {
        tips: 3,
        time: '2000'
    });
}


//展示加载中
export function bpoLoading(content) {
    /*加载中
    var index = layer.load();
    var index = layer.load(1);
    var index = layer.load(2);*/

    var index = layer.load(0, {
        shade: [0.1, '#fff'], //0.1透明度的白色背景

        // content: content,
        // success: function (layero) {
        //     layero.find('.layui-layer-content').css({
        //         'padding-top': '40px',
        //         'width': '60px'
        //     });
        // }
    });
    return index;
}

//关闭加载中
export function bpoHideLoading(index) {
    /*关闭layer.close(index);*/
    layer.close(index);
}
export function bpoAppQrcode() {
    var src = require('@/assets/fixedRight/supaiapp_ercode.png')
    var html = `<div style="border-radius: 10px;background-color: #fff;padding: 50px;text-align: center;">
        <img width="142px" height="142px" src="${src}" alt="">
        <div style="font-size: 14px;line-height: 24px;color: #333;margin-top:10px">扫码下载工业速派app <br>即可进行工程师注册流程</div>
      </div>`
    var layIndex = layer.open({
        type: 1,
        shade: 0.8,
        offset: 'auto',
        area: ['300px', '300px'],
        shadeClose: true, //点击外围关闭弹窗
        scrollbar: false, //不现实滚动条
        title: false, //不显示标题  
        closeBtn: false, //是否显示关闭按钮
        content: html, //捕获的元素，注意：最好该指定的元素要存放在body最外层，否则可能被其它的相对元素所影响  
        cancel: function() {
            //layer.msg(' ', { time: 5000, icon: 6 });  
        }
    });
}