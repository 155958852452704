//接口域名
import VueCookies from 'vue-cookies'
const PLATFORM = process.env.VUE_APP_MODE;
console.log("PLATFORM:"+PLATFORM)
let p_origin = 'http://www.gkweb.com'; //主站域名
let c_origin = 'http://c.gkweb.com'; //C站域名
let peixun = 'http://peixun.gongkongbpo.qa'; //培训站域名

let BASEURL = 'http://api.qa.gongkongbpo.com'; //接口域名
// let BASEURL = 'http://api.gongkongbpo.qa'; //接口域名

let personUrl = "http://person.qa.gongkongbpo.com"
// let personUrl = "http://person.gongkongbpo.qa"

let PersonLogin = 'http://ssoapi.qa.gongkongbpo.com';
// let PersonLogin = 'http://sso.api.gongkongbpo.qa';

let ZYDAPI = 'https://java-api.qa.gongkongbpo.com/service';
// let ZYDAPI = 'http://192.168.1.100/service';

let ActivityUrl = 'http://activityapi.qa.gongkongbpo.com'           //活动站点
// let ActivityUrl = 'http://activityapi.gongkongbpo.qa'           //活动站点
let ManageApiUrl="https://manageapi.insupai.com"; //后台接口站点
console.log("PLATFORM:"+PLATFORM);
if (PLATFORM == 'test') {
    p_origin = 'https://ts.insupai.com';
    c_origin = 'https://tc.insupai.com';
    peixun = 'https://tpeixun.insupai.com';
    BASEURL = 'https://test.api.insupai.com';
    // BASEURL = 'https://api.insupai.com';
    personUrl = "https://tperson.insupai.com"; 
    PersonLogin = 'http://tsso.api.gongkongbpo.com';
    
    ZYDAPI = 'https://testexternal.api.insupai.com/service';
    
    ActivityUrl = 'https://tactivityapi.insupai.com';
    ManageApiUrl="https://tmanageapi.insupai.com"; //后台接口站点
}
if (PLATFORM == 'production') {
    p_origin = 'https://www.insupai.com';
    c_origin = 'https://c.insupai.com';
    peixun = 'https://peixun.insupai.com';
    BASEURL = 'https://api.insupai.com';
    personUrl = "https://person.insupai.com";
    // PersonLogin = 'http://sso.api.gongkongbpo.qa';
    PersonLogin = 'http://sso.api.insupai.com';
    ZYDAPI = 'https://external.api.insupai.com/service';
    
    ActivityUrl = 'https://activityapi.insupai.com';
    ManageApiUrl="https://manageapi.insupai.com"; //后台接口站点
}
if (PLATFORM == 'development') {
    //  BASEURL = 'http://www.api.com';
    BASEURL = 'http://api.qa.gongkongbpo.com';

    personUrl = "http://person.qa.gongkongbpo.com"
    // personUrl = "http://person.gongkongbpo.qa"
    
    p_origin = 'http://www.qa.gongkongbpo.com';
    // p_origin = 'http://www.gongkongbpo.qa';
    
    c_origin = 'http://c.qa.gongkongbpo.com';
    // c_origin = 'http://c.gongkongbpo.qa';
    
    peixun = 'http://peixun.gongkongbpo.qa';

    ZYDAPI = 'https://java-api.qa.gongkongbpo.com/service';
    // ZYDAPI = 'http://192.168.1.100/service';
    

    PersonLogin = 'http://ssoapi.qa.gongkongbpo.com';
    // PersonLogin = 'http://sso.api.gongkongbpo.qa';
    ActivityUrl = 'http://activityapi.qa.gongkongbpo.com'  ;
    // ActivityUrl = 'http://activityapi.gongkongbpo.qa'  
    ManageApiUrl="http://manageapi.qa.gongkongbpo.com"; //后台接口站点
}
console.log("PLATFORM:"+PLATFORM);
console.log("ManageApiUrl:"+ManageApiUrl);
export const isDev = process.env.NODE_ENV == 'development' ? true : false;
export { p_origin, c_origin, BASEURL, personUrl, peixun, PersonLogin, ZYDAPI, ActivityUrl, ManageApiUrl};
//兼容ie
if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}
//toast提示
export function Toast(params) {
    var self = Toast;
    var options = {
        msg: '',
        icon: '',
        duration: 2000,
        position: 'middle',
        callback: null,
    }
    var paramsType = 0;
    if (typeof params == 'string') {
        paramsType = 1;
        options.msg = params;
    }
    if (typeof params == 'object' && Object.prototype.toString.call(params).toLowerCase() == "[object object]" && !params.length) {
        paramsType = 2;
        extend(options, params);
    }
    if (!paramsType) {
        return;
    }
    var div = document.createElement('div');
    var dom = document.getElementById('toastbox') ? document.getElementById('toastbox').parentNode : null;
    var html = `<div id="toastbox" class="toastbox show ${options.position}"><i class="toast-icon ${options.icon}"></i><div class="toast-text">${options.msg}</div></div>`;
    div.innerHTML = html;
    if (!dom) {
        document.body.appendChild(div);
    } else {
        document.body.removeChild(dom);
        document.body.appendChild(div);
    }
    clearTimeout(self.timer);
    self.timer = setTimeout(() => {
        document.getElementById('toastbox').className = 'toastbox ' + options.position;
        if (typeof options.callback == 'function') {
            options.callback();
        }
        clearTimeout(self.timer);
        document.body.removeChild(div);
    }, options.duration);
}
// 时间戳转时间日期  changeDate(1532590943000)
export function FormatTime(datetime, fmt = 'yyyy-MM-dd hh:mm:ss') {
    if (!datetime || datetime == "") {
        return "";
    }

    if (parseInt(datetime) == datetime) {
        if (datetime.length == 10) {
            datetime = parseInt(datetime) * 1000;
        } else if (datetime.length == 13) {
            datetime = parseInt(datetime);
        }
    }
    datetime = new Date(datetime);
    var o = {
        "M+": datetime.getMonth() + 1, //月份   
        "d+": datetime.getDate(), //日   
        "h+": datetime.getHours(), //小时   
        "m+": datetime.getMinutes(), //分   
        "s+": datetime.getSeconds(), //秒   
        "q+": Math.floor((datetime.getMonth() + 3) / 3), //季度   
        "S": datetime.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (datetime.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
// 合并对象
export function extend(target, source) {
    var obj = target || {}
    for (var key in source) {
        // obj[key] = source[key];
        if (typeof source[key] === "object" && source[key]) {
            obj[key] = (source[key].constructor === Array) ? [] : {};
            extend(obj[key], source[key])
        } else {
            obj[key] = source[key];
        }
    }
    return obj;
}
//获取当前url中  key=value
export function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var query = window.location.search.substr(1) || window.location.hash.substr(window.location.hash.indexOf('?') + 1);
    var r = query.match(reg);
    if (r != null) {
        return (r[2]);
    } else {
        var params = decodeURI(query);
        var arr = params.split('&');
        var obj = {};
        for (var i = 0; i < arr.length; i++) {
            var arrItem = arr[i].split('=');
            obj[arrItem[0]] = arrItem[1]
        }
        //如果有查询条件，且不匹配
        if (name) {
            return null;
        }
        //如果没有查询条件，返回参数对象
        if (params.indexOf('=') != -1) {
            //如果有参数，返回对象
            return obj;
        } else {
            return {};
        }
    }
}

//获取cookie值
export function getCookie(key) {
    var arr = document.cookie.split(';');
    var obj = {};
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i].split('=');
        if (item[0]) {
            obj[item[0].trim()] = item[1].trim();
        }
    }
    return obj[key];
}

//存储localStorage
export function setStorage(name, content) {
    if (!localStorage) { return; }
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}

//获取localStorage
export function getStorage(name) {
    if (!localStorage) { return; }
    if (!name) return;
    return window.localStorage.getItem(name);
}

//删除localStorage
export function removeStorage(name) {
    if (!localStorage) { return; }
    if (!name) return;
    window.localStorage.removeItem(name);
}
export function isJsonString(str) {
    try {
        if (typeof JSON.parse(str) == "object") {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
}
//企业登录设置cookie
export function setCompanyCookies(companyId = 0, companyName = '') {
    let hostname = window.location.hostname;
    let index = hostname.indexOf('.');
    let domain = hostname.substr(index + 1);
    VueCookies.set('CompanyIsLogin', 'true', '7d', '/', domain);
    VueCookies.set('KCompanyid', companyId, '7d', '/', domain);
    VueCookies.set('KCompanyName', companyName, '7d', '/', domain);
}
//个人登录设置cookie
export function setPersonCookies(token) {
    let hostname = window.location.hostname;
    let index = hostname.indexOf('.');
    let domain = hostname.substr(index + 1);
    VueCookies.set('token', token, '7d', '/', domain);
}