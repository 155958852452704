import Vue from 'vue'
import Vuex from 'vuex'
import WebIM from "@/scripts/WebIM";
import Chat from "./chat/chat";
import FriendModule from "./chat/friendModule";
import Group from "./chat/group";
import Emedia from "./chat/emedia";
import {
    getStorage,
    setStorage,
    removeStorage,
    getQueryString,
    setCompanyCookies,
    setPersonCookies
} from '@/scripts/util.js'
import {
    GetAccountData,
    GetAccountType,
    GetAccountFinanceInfo,
    CodeLines,
    ProductDomains,
    GetAreas,
    GetIndustry,
    SecondProductSelect
} from '@/scripts/api.js'
// import { reject } from 'core-js/fn/promise';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        userInfo: getStorage('userInfo') ? JSON.parse(getStorage('userInfo')) : null,
        permission: getStorage('permission') ? JSON.parse(getStorage('permission')) : null,
        mapPoint: getStorage('mapPoint') ? JSON.parse(getStorage('mapPoint')) : null,
        mapDistrict: JSON.parse(getStorage('mapDistrict')) || {},
        productions: JSON.parse(getStorage('productions')) || [],
        hxLoginId: getStorage('hxLoginId') || '',
        hxIsLogin: false,
        chatId: getStorage('chatId') || '',
        historyChatId: JSON.parse(getStorage('historyChatId')) || {contact: '', group: ''}, // 添加左侧tab切换时 存储的历史chatId | 值是对象
        chatType: '',
        serviceInfo: null, //服务站信息
        publishAgain: null, //上一单信息
        createConfig: {}, //发单配置
        accountBalance: {}, //余额、信用额度,
        bankList: [], //银行列表
        domainList: [], //领域列表
        provinceList: [], //省市区
        secondProductList: [], // 二级产品
    },
    getters: {
        createTypes(state) {
            //发单权限
            let arr = [];
            let _permission = state.permission;
            let role = _permission ? _permission.RoleData : []
            for (let i = 0; i < role.length; i++) {
                if (role[i].JurisdictionType == 1 && role[i].IsOpen) {
                    let obj = {...role[i] };
                    if (role[i].JurisdictionsCode == 1001) {
                        obj['jobType'] = 1;
                        obj['jobName'] = '自由平台';
                    }
                    if (role[i].JurisdictionsCode == 1003) {
                        obj['jobType'] = 6;
                        obj['jobName'] = 'B2B平台';
                    }
                    if (role[i].JurisdictionsCode == 1005) {
                        obj['jobType'] = 8;
                        obj['jobName'] = '内部工单';
                    }
                    arr.push(obj);
                }
            }
            return arr;
        }
    },
    mutations: {
        setUserInfo(state, value) {
            if (value) {
                setStorage('userInfo', value);
            } else {
                removeStorage('userInfo');
            }
            state.userInfo = value;
        },
        setPermission(state, value) {
            if (value) { 
                setStorage('permission', value); 
            } else {
                removeStorage('permission');
            }
            state.permission = value;
        },
        setMapPoint(state, value) {
            setStorage('mapPoint', value);
            state.mapPoint = value;
        },
        setMapDistrict(state, value) {
            setStorage('mapDistrict', value);
            state.mapDistrict = value;
        },
        setProductions(state, value) {
            setStorage('productions', value);
            state.productions = value;
        },
        setHxLoginId(state, value) {
            setStorage('hxLoginId', value);
            state.hxLoginId = value;
        },
        setHxLoginStatus(state, value) {
            setStorage('hxIsLogin', value);
            state.hxIsLogin = value;
        },
        //设置聊天对象
        setChatId(state, value) {
            setStorage('chatId', value);
            state.historyChatId[getQueryString('activeKey')] = value;
            setStorage('historyChatId', state.historyChatId)
            state.chatId = value;
        },
        //设置聊天方式
        setChatType(state, value) {
            setStorage('chatType', value);
            state.chatType = value;
        },
        //设置服务站信息
        setServiceInfo(state, value) {
            state.serviceInfo = value;
        },
        setPublishAgain(state, value) {
            state.publishAgain = value;
        },
        setCreateConfig(state, value) {
            state.createConfig = value;
        },
        setAccountBalance(state, value) {
            state.accountBalance = value;
        },
        setBankList(state, value) {
            state.bankList = value;
        },
        setDomainList(state, value) {
            state.domainList = value;
        },
        setProvince(state, value) {
            state.provinceList = value;
        },
        setSecondProductList(state, value){
            state.secondProductList = value;
        }
    },
    actions: {
        //获取个人账号信息
        loadAccountData(context,loading) {
            var isLoading = typeof(loading)=='undefined'?false:loading;
           // console.log("index.js loadAccountData:"+isLoading+"; typeof(loading)="+ typeof(loading));
            let data = context.state.userInfo;
            let params = {};
            if (!data) {
                return;
            }
            if (data.LoginAccountType == 1) {
                params['userId'] = data.LoginAccountId;
            }
            if (data.LoginAccountType == 2) {
                params['companyId'] = data.LoginAccountId;
            }
            return new Promise(function(resolve, reject) {
                GetAccountData(params,isLoading).then(r => {
                    if (r.Result == 1) {
                        let data = r.Data;
                        if (data.UserId) {
                            data['LoginAccountType'] = 1;
                            data['LoginAccountId'] = data.UserId;
                            data['UserCode'] = 'p' + data.UserId;
                        } else if (data.CompanyId) {
                            data['LoginAccountType'] = 2;
                            data['LoginAccountId'] = data.CompanyId;
                            data['UserCode'] = 'e' + data.CompanyId;
                            setCompanyCookies(data.CompanyId, data.CompanyName);
                        }
                        context.commit('setUserInfo', data);

                        resolve(data)
                    } else {
                        reject(r)
                    }
                }).catch(e => {
                    reject(e)
                })
            })

        },
        //获取个人权限
        loadPermission(context,loading) {
            var isLoading = typeof(loading)=='undefined'?false:loading;

            return new Promise((resolve, reject) => {
                let data = context.state.userInfo;
                let params = {};
                if (!data) {
                    return;
                }
                if (data.LoginAccountType == 1) {
                    params['userId'] = data.LoginAccountId;
                }
                if (data.LoginAccountType == 2) {
                    params['companyId'] = data.LoginAccountId;
                }
                // console.log("获取权限");
                GetAccountType(params,isLoading).then(r => {
                    if (r.Result == 1) {
                        context.commit('setPermission', r.Data);
                        resolve(r.Data);
                        // context.dispatch('hxLogin', {
                        //     username: r.Data.ImUserId,
                        //     password: r.Data.ImUserPassWord,
                        // })
                    } else {
                        reject(r)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        //获取用户余额信用额度信息
        loadAccountBalance(context) {
            let data = context.state.userInfo;
            if (!data) {
                return;
            }
            return new Promise((resolve, reject) => {
                GetAccountFinanceInfo({
                    UserCode: data.UserCode
                }).then(r => {
                    if (r.Result == 1) {
                        context.commit('setAccountBalance', r.Data);
                        resolve(r.Data);
                    } else {
                        reject(r);
                    }
                })
            })
        },
        //获取银行列表
        loadBankList(context, params) {
            CodeLines({
                CodeName: params
            }).then(r => {
                if (r.Result == 1) {
                    if (params == 'Bank') {
                        let data = r.Data.Lines || [];
                        context.commit('setBankList', data);
                    }
                }
            })
        },
        //获取领域列表
        loadDomainList(context, params = true) {
            return new Promise((resolve, reject) => {
                ProductDomains({
                    IsReturnOtherDomain: params,
                }).then(r => {
                    if (r.Result == 1) {
                        let data = r.Data || [];
                        context.commit('setDomainList', data);
                        resolve(r);
                    } else {
                        reject(r)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        //获取领域列表
        loadProvinceList(context, params = true) {
            return new Promise((resolve, reject) => {
                GetAreas().then(r => {
                    if (r.Result == 1) {
                        let data = r.Data.province || [];
                        context.commit('setProvince', data);
                        resolve(r);
                    } else {
                        reject(r)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        //获取行业列表
        loadIndustryList(context, parentId = 0) {
            return new Promise((resolve, reject) => {
                GetIndustry({
                    parentID: parentId
                }).then(r => {
                    if (r.Result == 1) {
                        let data = r.Data || [];
                        resolve(data);
                    } else {
                        reject(r)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        // 获取二级产品
        getSecondProductList(context) {
            SecondProductSelect().then(r=>{
                if (r.Result == 1) {
                    context.commit('setSecondProductList', r.Data.ProductList);
                }
            })
          },
    },
    modules: {
        chat: Chat,
        friendModule: FriendModule,
        group: Group,
        emedia: Emedia,
    }
})