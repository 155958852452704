<template>
  <div class="password-dialog" :v-show="show">
    <div class="dialog-wrapper">
      <div class="dialog-title">支付密码</div>
      <i class="icon-close"  @click="onClose">×</i>
      <div class="password-box">
        <input
          ref="pwd"
          type="text"
          maxlength="6"
          v-model="msg"
          class="pwd"
          unselectable="on"
        />
        <ul class="pwd-wrap" @click="focus">
          <li :class="msg.length == 0 ? 'psd-blink' : ''">
            <i v-if="msg.length > 0"></i>
          </li>
          <li :class="msg.length == 1 ? 'psd-blink' : ''">
            <i v-if="msg.length > 1"></i>
          </li>
          <li :class="msg.length == 2 ? 'psd-blink' : ''">
            <i v-if="msg.length > 2"></i>
          </li>
          <li :class="msg.length == 3 ? 'psd-blink' : ''">
            <i v-if="msg.length > 3"></i>
          </li>
          <li :class="msg.length == 4 ? 'psd-blink' : ''">
            <i v-if="msg.length > 4"></i>
          </li>
          <li :class="msg.length == 5 ? 'psd-blink' : ''">
            <i v-if="msg.length > 5"></i>
          </li>
        </ul>
      </div>
      <p class="tips">温馨提示：为了您的资金安全，请勿线下交易！</p>
      <div class="btn-group">
        <div class="btn-primary" @click="submit">支付</div>
        <span class="btn-forget" @click="forget">忘记密码</span>
      </div>
    </div>
  </div>
</template>
<script>
import {aesEncrypt} from '@/scripts/aesHelper.js'
export default {
  name:'password-dialog',
  data(){
    return{
      show:false,
      msg:'',
    }
  },
  watch:{
    show(value){
      if(value){
        this.msg='';
        this.$refs.pwd.focus();
      }
    }
  },
  methods: {
    //输入支付密码
    focus() {
      this.$refs.pwd.focus();
    },
    onClose(){
      this.close()
    },
    submit(){
      if(this.msg.length<6){
        this.$toast('请输入密码！');
        return;
      }
      let password=this.msg;
      this.onConfirm(password)
    },
    forget(){
      this.onCancel();
    }
  },
}
</script>
<style lang="scss">
.password-dialog{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  .dialog-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 500px;
    transform: translate3d(-50%,-50%,0);
    box-shadow: 0 0 5px rgba(0,0,0,.5);
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
  }
  .dialog-title{
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background-color: #f75959;
    color: #fff;
    font-size: 16px;
  }
  .icon-close{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  .password-box{
    .pwd{
      width: 0.1px;
      height: 0.1px;
      color: transparent;
      background: #000000;
      border: none;
      font-size: 18px;
      opacity: 0;
    }
    .pwd-wrap {
      width: 436px;
      margin: 0 auto;
      background: #fff;
      cursor: pointer;
      z-index: 10;
      overflow: hidden;
      li {
        list-style-type: none;
        text-align: center;
        height: 56px;
        width: 56px;
        border: 1px solid #ddd;
        position: relative;
        margin-right: 20px;
        float: left;
        i {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: #000;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%,-50%,0);
        }
        &:last-child{
          margin-right: 0;
        }
      }
      .psd-blink {
        border-color: lightblue;
        box-shadow: 0 0 4px lightblue;
      }
    }
  }
  .tips{
    font-size: 12px;
    color: #999;
    width: 260px;
    margin: 10px auto;
  }
  .btn-group{
    position: relative;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .btn-primary{
    width: 100px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    font-size: 14px;
    cursor: pointer;
  }
  .btn-forget{
    font-size: 14px;
    color: #f75959;
    line-height: 30px;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: 60px;
    cursor: pointer;
  }
}
</style>