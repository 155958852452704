import Vue from 'vue'
import Password from './password.vue'
let confirmVueLoading;
let passwordConstructor = Vue.extend(Password);
passwordConstructor.prototype.close = function() {
    let vm = this;
    confirmVueLoading = null;
    return new Promise((resolve, reject) => {
        if (vm.$el && vm.$el.parentNode) {
            vm.$el.parentNode.removeChild(vm.$el);
        } else {
            reject();
        }
        resolve(vm.pwd);
        vm.destroy();
        vm.show = false;
    })
}
passwordConstructor.prototype.callback = function(params) {
    return new Promise((resolve, reject) => {
        resolve(params);
    })
}
const defaults = {
    show: false,
    buttons: [],
    onConfirm() {},
    onCancel() {}
}
const password = function(params = {}) {
    if (Vue.prototype.$isServer) return;
    let options = Object.assign({}, defaults, params);
    if (confirmVueLoading) {
        return confirmVueLoading;
    }
    let parent = document.body;
    let instance = new passwordConstructor({
        el: document.createElement('div'),
        data: options
    })
    parent.appendChild(instance.$el);
    Vue.nextTick(() => {
        instance.show = true;
    })
    confirmVueLoading = instance;
    return instance;
}
export default password