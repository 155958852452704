import request from './request.js';
import { BASEURL, getStorage, ZYDAPI, ActivityUrl,ManageApiUrl } from '@/scripts/util.js'
//错误上传
export function AddErrorLog(api = '', msg = '', data = '') {
    let explorer = window.navigator.userAgent.toLowerCase();
    let userAgent = {};
    //ie
    if (explorer.indexOf("msie") >= 0) {
        let ver = explorer.match(/msie ([\d.]+)/)[1];
        userAgent = { PhoneEdition: "IE", PhoneType: ver };
    }
    //firefox
    else if (explorer.indexOf("firefox") >= 0) {
        let ver = explorer.match(/firefox\/([\d.]+)/)[1];
        userAgent = { PhoneEdition: "Firefox", PhoneType: ver };
    }
    //Chrome
    else if (explorer.indexOf("chrome") >= 0) {
        let ver = explorer.match(/chrome\/([\d.]+)/)[1];
        userAgent = { PhoneEdition: "Chrome", PhoneType: ver };
    }
    //Opera
    else if (explorer.indexOf("opera") >= 0) {
        var ver = explorer.match(/opera.([\d.]+)/)[1];
        userAgent = { PhoneEdition: "Opera", PhoneType: ver };
    }
    //Safari
    else if (explorer.indexOf("Safari") >= 0) {
        let ver = explorer.match(/version\/([\d.]+)/)[1];
        userAgent = { PhoneEdition: "Safari", PhoneType: ver };
    }
    let userInfoStr = getStorage('userInfo');
    let userInfo = '';
    if (userInfoStr) {
        userInfo = JSON.parse(userInfoStr);
    }
    let params = {
        ...userAgent,
        InterfaceName: api,
        ErrorLog: msg,
        UserCode: userInfo.UserCode,
        PageName: window.location.href,
        Referrer: 4,
        SourceData: data
    }
    return request('post', '/api/Log/AddErrorLog', params, false);
}
// 上传url
export const UploadImg = `${BASEURL}/api/Upload/UploadImg`;
// // 上传文件
// export function FileUpload(params) {
//     return request('post', '/api/Upload/UploadImg', params);
// }
//获取用户信息
export function GetAccountData(params,loading) {
    var isLoading = typeof(loading)=='undefined'?true:loading;
    console.log("GetAccountData:"+isLoading);
    return request('post', '/api/UserCenter/GetAccountData', params,isLoading);
}
//获取权限和im名
export function GetAccountType(params,loading) {
    var isLoading = typeof(loading)=='undefined'?true:loading; 
    return request('post', '/api/AppCompany/GetAccountType', params,isLoading);
}
//工单详情
export function pickOrderDetails(params) {
    return request('post', '/JobWorkflow/PickJob/GetWebPickJobInfo', params);
}
//省市
export function GetAreas(params) {
    return request('post', '/ka/BpoServiceUser/GetAreas', params);
}
//企业承接
export function confirmPick(params) {
    return request('post', '/JobWorkflow/PickJob/ConfirmPickAndEngineer', params);

}
//释放
export function releaseOrder(params) {
    return request('post', '/JobWorkflow/PickJob/ReleaseJob', params);
}
//获取工程师下拉框（企业）
export function GetCompanyEngineer(params) {
    return request('post', '/JobWorkflow/PickJob/GetCompanyEngineer', params);
}
//指定工程师
export function ConfirmEngineer(params) {
    return request('post', '/JobWorkflow/PickJob/ConfirmEngineer', params);
}
//获取工单的进度，用于刷新工单的进度
export function GetProgressList(params) {
    return request('post', '/JobWorkflow/PickJob/GetJobProgressList', params);
}
//添加服务进度 7=>工程师已出发 、 8=>工程师已到达现场
export function AddProgress(params) {
    return request('post', '/JobWorkflow/PickJob/AddJobProgress', params);
}
// // 迭代1
// //到场确认照片提交
// export function ConfirmOrderTask(params) {
//     return request('post', '/JobWorkflow/PickJob/ConfirmJobTask', params)
// }
// // 离场确认
// export function AddLeaveConfirmInfo(params) {
//     return request('post', '/JobWorkflow/PickJob/AddLeaveConfirmInfo', params)
// }
// //点击保存产品确认
// export function ConfirmOrderProductCate(params) {
//     return request('post', '/JobWorkflow/PickJob/ConfirmJobProductCate', params)
// }
//获取服务报告信息（工单服务日志，服务单文件，相关文档，费用信息等）
export function GetOrderReportInfo(params) {
    return request('post', '/JobWorkflow/PickJobCenter/GetJobReportInfo', params)
}
//提交工程师日志
export function SubmitWorkLog(params) {
    return request('post', '/JobWorkflow/PickJobCenter/AddJobWorkLog', params)
}
//编辑工程师日志
export function UpdateEngineerDayWorkLog(params) {
    return request('post', '/JobWorkflow/PickJobCenter/UpdateEngineerDayWorkLog', params)
}
//删除工程师日志
export function RemoveWorkLog(params) {
    return request('post', '/JobWorkflow/PickJobCenter/RemoveJobWorkLog', params)
}
// 获取工程师日志
export function GetJobWorkLogDefaultTime(params) {
    return request('post', '/JobWorkflow/PickJobCenter/GetJobWorkLogDefaultTime', params)
}
// 下载服务单之前是否是第一次下载服务单接口
export function IsDownJobReport(params) {
    return request('post', '/JobWorkflow/PickJob/IsDownJobReport', params)
}
// //上传服务单
// export function AddReportFile(params) {
//     return request('post', '/JobWorkflow/PickJobCenter/AddReportFile', params)
// }
// //删除服务单
// export function RemoveReportFile(params) {
//     return request('post', '/JobWorkflow/PickJobCenter/DeleteReportFile', params)
// }
//企业提交服务单
export function CompanySubmitReport(params) {
    return request('post', '/JobWorkflow/PickJobCenter/CompanySubmitReport', params)
}
//接单方评价
export function SaveJobEvaluation(params) {
    return request('post', '/JobWorkflow/PickJobCenter/SaveJobEvaluation', params)
}
//工程师下载服务单协议 HTML
export function GetSafetyContent() {
    return request('post', '/JobWorkflow/PickJob/GetSafetyContent')
}
// //工程师提交服务单信息
// export function EngineerSubmitReport(params) {
//     return request('post', '/JobWorkflow/PickJobCenter/EngineerSubmitReport', params)
// }
//免费呼叫接口 
export function FreeCallPhone(params) {
    return request('post', '/api/BpoPrivateNumber/AxbBindPhone', params)
}
//获取工单配置
export function GetAccountJobConfig(params) {
    return request('post', '/api/UserCenter/GetAccountJobConfig', params);
}
//发布工单第一步
export function AddJobBase(params) {
    return request('post', '/api/JobWorkflow/AddJob/AddJobBase', params);
}
//常用地址列表
export function GetJobAddressList(params) {
    return request('post', '/api/BpoJobAddress/GetJobAddressList', params);
}
//服务类型
export function ServiceStagesByDomain(params) {
    return request('post', '/api/Code/ServiceStagesByDomain', params);
}
// //服务类型标签
// export function ServiceStageTags(params) {
//     return request('post', '/api/Code/ServiceStageTags', params);
// }
//获取领域
export function ProductDomains(params) {
    return request('post', '/api/Code/ProductDomains', params);
}
//获取产品品牌-新接口
export function ProductBrands(params) {
    return request('post', '/api/Code/ProductBrands ', params);
}

// 获取二级产品
export function SecondProductSelect(data) {
    let params = {
        "LevelType": 2,
        "ParentId": 0,
        "platform": "pcweb"
    }
    return request('post', '/api/Code/GetProductList', params);
}

// 获取三级产品
export function ThreeProductList(data) {
    let params = {
        "LevelType": 0,
        "platform": "pcweb",
        ...data
    }
    return request('post', '/api/Code/GetProductList', params);
}

// //获取产品系列
// export function GetProductsByBandPCate(params) {
//     return request('post', '/ka/AddJob/GetProductsByBandPCate', params);
// }
// //获取产品品牌
// export function GetBrandByProductCategory(params) {
//     return request('post', '/ka/AddJob/GetBrandByProductCategory ', params);
// }
// //获取一级产品类型
// export function GetOneProductCategory(params) {
//     return request('post', '/ka/AddJob/GetOneProductCategory', params);
// }
//获取所属行业列表
export function GetIndustry(params) {
    return request('get', '/api/BpoJob/GetIndustry', params);
}
export function GetDropDownIndustryList(params) {
    return request('post', '/api/Code/GetDropDownIndustryList', params);
}
//发单第二步提交
export function AddJobDetail(params) {
    return request('post', '/api/JobWorkflow/AddJob/AddJobDetail', params);
}

//工单基本信息
export function GetPCJobBaseInfo(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetPCJobBaseInfo', params);
}
//工单信息
export function GetJobInfo(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobInfo', params);
}
//押金信息
export function GetJobDepositDetail(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobDepositDetail', params);
}
//接单方信息
export function GetPickJobInfo(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetPickJobInfo', params);
}
//工单服务报告
export function GetJobServiceReport(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobServiceReport', params);
}
//第三方服务报告
export function GetThirdReport(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetThirdReport', params);
}
//获取评价信息
export function GetJobEvaluate(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobEvaluate', params);
}
//投标人列表
export function GetTenderList(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetTenderList', params);
}
//取消工单
export function CancelJob(params) {
    return request('post', '/api/JobWorkflow/AddJob/CancelJob', params);
}
//提交发单方评价
export function SubmitJobServiceCommend(params) {
    return request('post', '/api/JobWorkflow/AddJob/SubmitJobServiceCommend', params);
}
//工单支付信息
export function GetAccountPayInfo(params) {
    return request('post', '/api/BpoJobPay/GetAccountPayInfo', params);
}
//创建支付订单
export function CreatePayOrder(params) {
    return request('post', '/api/BpoJobPay/CreatePayOrder', params);
}
//确认支付
export function JobAgreePay(params) {
    return request('post', '/JobWorkflow/AddJobCenter/JobAgreePay', params);
}
//承接单详情
// export function GetJobApplyOrderDetail(params) {
//     return request('post', '/api/JobWorkflow/AddJob/GetJobApplyOrderDetail', params);
// }
//服务站详情
export function GetServiceSiteInfo(params) {
    return request('post', '/api/BpoCompanyService/GetServiceSiteInfo', params);
}
//工程师详情
export function GetUserEngineerInfo(params) {
    return request('post', '/api/UserCenter/GetUserEngineerInfo', params);
}
//下载工单资料
export function DownJobFile(params) {
    return request('get', '/JobWorkflow/PickJobCenter/DownJobFile', params);
}
//终止结算
export function JobEndSettlement(params) {
    return request('post', '/JobWorkflow/AddJobCenter/JobEndSettlement', params);
}
//工单变更详情
export function GetJobExceptionView(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobExceptionView', params);
}
//结算明细
export function GetJobSettleDetail(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobSettleDetail', params);
}
//确认内部工单服务报告
export function ConfirmInternalJobServiceReport(params) {
    return request('post', '/api/JobWorkflow/AddJob/ConfirmInternalJobServiceReport', params);
}
//分配菜单
export function GetInternalJobAssianMenu(params) {
    return request('post', '/api/BpoJob/GetInternalJobAssianMenu', params);
}
//分配用户列表
export function GetInternalJobAssianUsersSelect(params) {
    return request('post', '/api/BpoJob/GetInternalJobAssianUsersSelect', params);
}
//确认分配用户
export function ConfirmInternalJobAssign(params) {
    return request('post', '/api/BpoJob/ConfirmInternalJobAssign', params);
}
//推荐接单人列表
export function GetJobRecommendReceiverList(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobRecommendReceiverList', params);
}
//邀请接单
export function InviteJob(params) {
    return request('post', '/api/JobWorkflow/AddJob/InviteJob', params);
}
//个人中心——总览
export function GetUserSaleData(params) {
    //销售统计数据
    return request('post', '/SalesStatistics/UserSale/GetUserSaleData', params);
}
export function GetUserPickJobData(params) {
    //接单信息总览
    return request('post', '/SalesStatistics/UserSale/GetUserPickJobData', params);
}
export function GetUserSendJobData(params) {
    // 发单信息总览
    return request('post', '/SalesStatistics/UserSale/GetUserSendJobData', params);
}
export function GetUserFinanceDataAndPermission(params) {
    // 财务信息
    return request('post', '/SalesStatistics/UserSale/GetUserFinanceDataAndPermission', params);
}
//个人中心——客户列表
export function GetWebSaleCompanyList(params) {
    return request('post', '/SalesStatistics/UserSale/GetWebSaleCompanyList', params);
}
//个人中心——活动列表
export function GetActivitiesByUser(params) {
    return request('post', '/api/Activity/GetActivitiesByUser', params);
}
//个人中心——下载客户列表
export function DownWebSaleCompanyList(params) {
    window.location.href = BASEURL + "/SalesStatistics/UserSale/DownWebSaleCompanyList?" + params
}
//个人中心——客户工单列表
export function GetSaleCustomerJobOrderList(params) {
    return request('post', '/SalesStatistics/UserSaleJobOrder/GetSaleCustomerJobOrderList', params);
}
//个人中心——客户工单列表
export function DownExcelSaleCustomerJobOrderList(params) {
    window.location.href = BASEURL + "/SalesStatistics/UserSaleJobOrder/DownExcelSaleCustomerJobOrderList?" + params
}
//企业中心——我的团队
export function GetTeamUserList(params) {
    return request('post', '/api/BpoCompanyService/GetTeamUserList', params);
}
//企业中心——删除团队成员
export function DeleteTeamUser(params) {
    return request('post', '/api/BpoCompanyService/DeleteTeamUser', params);
}
//企业中心——添加团队成员
export function AddTeamUser(params) {
    return request('post', '/api/BpoCompanyService/AddTeamUser', params);
}
//企业中心——客户列表
export function GetWebCompanyClientList(params) {
    return request('post', '/SalesStatistics/CompanySale/GetWebCompanyClientList', params);
}
//企业中心——下载客户列表
export function DownWebCompanyClientList(params) {
    window.location.href = BASEURL + '/SalesStatistics/CompanySale/DownWebCompanyClientList?' + params;
}
//企业中心——子公司客户列表
export function GetWebChildCompanyClientList(params) {
    return request('post', '/SalesStatistics/CompanySale/GetWebChildCompanyClientList', params);
}
//企业中心——下载子公司客户列表
export function DownWebChildCompanyClientList(params) {
    window.location.href = BASEURL + '/SalesStatistics/CompanySale/DownWebChildCompanyClientList?' + params;
}
//企业中心——公司销售列表
export function GetCompanySaleJobOrderList(params) {
    return request('post', '/SalesStatistics/UserSaleJobOrder/GetCompanySaleJobOrderList', params);
}
//企业中心——下载公司销售列表
export function DownExcelCompanySaleJobOrderList(params) {
    window.location.href = BASEURL + '/SalesStatistics/UserSaleJobOrder/DownExcelCompanySaleJobOrderList?' + params;
}
//企业中心——子公司销售列表
export function GetParentCompanyJobOrderList(params) {
    return request('post', '/SalesStatistics/UserSaleJobOrder/GetParentCompanyJobOrderList', params);
}
//企业中心——下载子公司销售列表
export function DownExcelParentCompanyJobOrderList(params) {
    window.location.href = BASEURL + '/SalesStatistics/UserSaleJobOrder/DownExcelParentCompanyJobOrderList?' + params;
}
// ///企业中心——总览
export function GetWebChildCompanySaleData(params) {
    //子公司销售信息总览
    return request('post', '/SalesStatistics/CompanySale/GetWebChildCompanySaleData', params);
}
export function GetChildCompanyPickJobData(params) {
    //子公司接单信息总览
    return request('post', '/SalesStatistics/CompanySale/GetChildCompanyPickJobData', params);
}
export function GetChildCompanySendJobData(params) {
    //子公司发单信息总览
    return request('post', '/SalesStatistics/CompanySale/GetChildCompanySendJobData', params);
}
export function GetCompanySaleData(params) {
    //销售信息总览
    return request('post', '/SalesStatistics/CompanySale/GetCompanySaleData', params);
}
export function GetCompanyPickJobData(params) {
    //接单信息总览
    return request('post', '/SalesStatistics/CompanySale/GetCompanyPickJobData', params);
}
export function GetCompanySendJobData(params) {
    // 发单信息总览
    return request('post', '/SalesStatistics/CompanySale/GetCompanySendJobData', params);
}
export function GetCompanyFinanceDataAndPermission(params) {
    // 财务信息
    return request('post', '/SalesStatistics/CompanySale/GetCompanyFinanceDataAndPermission', params);
}
export function GetUserJurisdiction(params) {
    // 获取权限
    return request('post', '/ka/UserCenter/GetUserJurisdiction', params);
}
// export function UpdateUsersRoles(params) {
//     // 修改权限
//     return request('post', '/ka/UserCenter/UpdateUsersRoles', params);
// }
export function UpdateReportTemplateFile(params) {
    // 上传/修改 服务单模板
    return request('post', '/SalesStatistics/CompanySale/UpdateReportTemplateFile', params);
}
//获取头部菜单
export function GetWebHeaderData(params) {
    return request('post', '/Api/AppCompany/GetWebHeaderData', params);
}
//获取左侧菜单
export function GetWebLeftData(params) {
    return request('post', '/Api/AppCompany/GetWebLeftData', params);
}
//个人实名认证
export function RealNamePersonAuth(params) {
    return request('post', '/api/User/RealNamePersonAuth', params);
}
//企业实名认证
export function RealNameEnterpriseAuth(params) {
    return request('post', '/api/User/RealNameEnterpriseAuth', params);
}
//合同管理
export function GetUserContractList(params) {
    return request('post', '/api/BpoContract/GetUserContractList', params);
}
//获取pdf地址
export function ConvertPDF(params) {
    console.log("ManageApiUrl:"+ManageApiUrl);
    return request('post', ManageApiUrl+'/api/BpoContract/ConvertPDF', params);
}
//签约合同
export function UserSignContract(params) {
    return request('post', '/api/BpoContract/UserSignContract', params);
}
//签约时发送验证码
export function TianWeiSendMobileCode(params) {
    return request('post', '/ChuJu/HYCommon/TianWeiSendMobileCode', params);
}
//ocr文字识别身份证
export function scanID(params) {
    let url = window.location.protocol + '//personcard.market.alicloudapi.com/ai-market/ocr/personid'
    return request('post', url, params);
}
//ocr文字识别营业执照
export function scanLicense(params) {
    let url = window.location.protocol + '//blicence.market.alicloudapi.com/ai_business_license';
    return request('post', url, params);
}
//代发单二维码
export function CreateReplaceSendJobQrCode(params) {
    return request('post', '/api/JobWorkflow/AddJob/CreateReplaceSendJobQrCode', params)
}
//代发单信息
export function GetReplaceSendJob(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetReplaceSendJob', params)
}
//获取B2B投标详情
export function GetJobDetailIndex(params) {
    return request('post', '/api/BpoJob/GetJobDetailIndex', params)
}
//B2B投标地址列表
export function GetJobB2BAddressList(params) {
    return request('post', '/api/Account/Account/ListAccountAddress', params)
}
//B2B投标添加地址
export function AddAccountAddress(params) {
    return request('post', '/api/Account/Account/AddAccountAddress', params)
}
//B2B投标编辑地址
export function UpdateAccountAddress(params) {
    return request('post', '/api/Account/Account/UpdateAccountAddress', params)
}
//B2B投标删除地址
export function DeleteAccountAddress(params) {
    return request('post', '/api/Account/Account/DeleteAccountAddress', params)
}
//B2B确认投标
export function B2BJobTender(params) {
    return request('post', '/JobWorkflow/PickJob/B2BJobTender', params)
}
//B2B获取标准工单的价格信息
export function GetB2BStandardJobPriceDetail(params) {
    return request('post', '/JobWorkflow/PickJob/GetB2BStandardJobPriceDetail', params)
}
//获取接单列表跳转页面
export function GetPcJobRouteUrl(params) {
    return request('post', '/JobWorkflow/PickJobCenter/GetPcJobRouteUrl', params)
}
//服务流程
export function GetServiceProcessView(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetServiceProcessView', params)
}
//到场确认数据
export function GetConfirmJobTaskInfo(params) {
    return request('post', '/JobWorkflow/PickJob/GetConfirmJobTaskInfo', params)
}
//离场确认数据
export function GetLeaveConfirmInfo(params) {
    return request('post', '/JobWorkflow/PickJob/GetLeaveConfirmInfo', params)
}
//根据工程师Id获取工单进度接口
export function GetJobProgressListTwo(params) {
    return request('post', '/JobWorkflow/PickJob/GetJobProgressList', params)
}
//添加获取释放,取消原因接口
export function GetOperationReason(params) {
    return request('post', '/JobWorkflow/PickJob/GetOperationReason', params)
}
// //工单群详情
export function GetJobGroupInfo(params) {
    return request('post', '/api/Im/GetJobGroupInfo', params)
}
//获取邀请用户列表
export function GetInviteUsers(params) {
    return request('post', '/api/Im/GetInviteUsers', params)
}
//添加群成员
export function AddGroupMember(params) {
    return request('post', '/api/Im/AddGroupMember', params)
}
//移除群成员
export function DeleteGroupMember(params) {
    return request('post', '/api/Im/DeleteGroupMember', params)
}
//服务站替工程师提交服务报告
export function PcEngineerSubmitJobReport(params) {
    return request('post', '/JobWorkflow/PickJobCenter/PcEngineerSubmitJobReport', params)
}
//更换工程师
export function ChangeJobEngineer(params) {
    return request('post', '/JobWorkflow/PickJob/ChangeJobEngineer', params)
}
// //电话外呼
// export function AXBindPhone(params) {
//     return request('post', '/api/BpoPrivateNumber/AXBindPhone', params)
// }
//是否显示聊天按钮
export function isShowChatBtnFun(params) {
    return request('post', '/api/Im/GetJointGroups', params)
}
// ############################## 聊天模块接口  以下 ################################
//修改群组信息
export function AddTourist(params) {
    return request('post', '/api/Im/AddTourist', params)
}
//修改群组信息
export function ChangGroupInfo(params) {
    return request('post', '/api/IM/ChangGroupInfo', params)
}
//单聊列表
export function GetUserSingleChats(params) {
    return request('post', '/api/Im/GetUserSingleChats', params)
}
//群聊列表
export function GetJointGroups(params) {
    return request('post', '/api/Im/GetJointGroups', params)
}
// //用户信息
export function GetUserImInfo(params) {
    return request('post', '/api/Im/GetUserImInfo', params)
}
//手机验证码
export function SendMobileCode(params) {
    return request('post', '/ChuJu/HYCommon/SendMobileCode', params);
}
//企业注册
export function CompanyRegister(params) {
    return request('post', '/api/AppCompany/CompanyRegister', params, false);
}
//企业注册
export function IsShowChatButton(params) {
    return request('post', '/api/Im/IsShowChatButton', params);
}
// //个人注册
// export function Signup(params) {
//     return request('post', '/register/signup', params);
// }
//企业登录
export function CompanyLogin(params) {
    // return request('post', '/api/AppCompany/CompanyLogin', params);
    return request('post', ZYDAPI + '/Account/AccountAuth/CompanyLogin', params)
}
// 企业手机号登录
export function CompanyMobileLogin(params) {
    // return request('post', '/api/Account/AccountAuth/CompanyMobileLogin', params)
    return request('post', ZYDAPI + '/Account/AccountAuth/CompanyMobileLogin', params)
}
//获取企业登录验证码 
export function SendValidCode(params) {
    return request('post', '/ChuJu/HYCommon/SendValidCode', params)
}
// //获取个人验证码
// export function sendloginvalidatecode(params) {
//     return request('get', '/validate/sendloginvalidatecode', params);
// }
//个人密码登录
export function UserAccountLogin(params) {
    // return request('post', '/api/Account/AccountAuth/UserAccountLogin', params);
    return request('post', ZYDAPI + '/Account/AccountAuth/UserAccountLogin', params)
}
//个人验证码登录
export function UserMobileLogin(params) {
    // return request('post', '/api/Account/AccountAuth/UserMobileLogin', params);
    return request('post', ZYDAPI + '/Account/AccountAuth/UserMobileLogin', params)
}
//获取公司列表
export function GetCompanyList(params) {
    return request('post', '/api/BpoCompanyService/GetCompanyList', params);
}
//证书类型和银行列表
export function CodeLines(params) {
    return request('post', '/api/Code/CodeLines', params);
}
//查询工程师
export function SearchEngineer(params) {
    return request('post', '/api/BpoCompanyService/SearchEngineer', params);
}
//企业用户认证服务站时签协议
export function CompanySignServiceStationContract(params) {
    return request('post', '/api/BpoContract/CompanySignServiceStationContract', params);
}
//个人注册
export function UserRegister(params) {
    return request('post', '/api/Account/AccountAuth/UserRegister', params);
}
//获取服务站认证信息
export function GetServiceSiteAuthInfo(params) {
    return request('post', '/api/Account/AccountServiceSite/GetServiceSiteAuthInfo', params);
}
//服务站认证
export function ApplyServiceStation(params) {
    return request('post', '/api/BpoCompanyService/ApplyServiceStation', params);
}
//编辑服务站信息
export function SubmitServiceSiteEdit(params) {
    return request('post', '/api/Account/AccountServiceSite/SubmitServiceSiteEdit', params);
}
// 创建服务站支付认证费用支付单
export function CreateServiceSitePayOrder(params) {
    return request('post', '/api/Account/AccountServiceSite/CreateServiceSitePayOrder', params);
}
//再发一单
export function GetRepublishSendJob(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetRepublishSendJob', params)
}
// 获取投标信息参数
export function GetPickMarketJobPara(params) {
    return request('post', '/JobWorkflow/PickJob/GetPickMarketJobPara', params)
}
// 自由平台投标
export function PickMarketJob(params) {
    return request('post', '/JobWorkflow/PickJob/PickMarketJob', params)
}
// 获取自由平台投标信息
export function GetMarketJobTenderInfo(params) {
    return request('post', '/JobWorkflow/PickJob/GetMarketJobTenderInfo', params)
}
// 修改自由平台投标信息
export function UpdateMarketTenderInfo(params) {
    return request('post', '/JobWorkflow/PickJob/UpdateMarketTenderInfo', params)
}
// 提交服务报告时更换工程师，添加或者删除单个工程师
export function TransformJobEngineer(params) {
    return request('post', '/JobWorkflow/PickJob/TransformJobEngineer', params)
}
//城市列表
export function GetSelectCityView(params) {
    return request('post', '/api/Account/AccountAddress/GetSelectCityView', params)
}
//发单地址列表
export function ListJobAddress(params) {
    return request('post', '/api/Account/AccountAddress/ListJobAddress', params)
}
//添加发单地址
export function AddJobAddress(params) {
    return request('post', '/api/Account/AccountAddress/AddJobAddress', params)
}
//修改发单地址
export function UpdateJobAddress(params) {
    return request('post', '/api/Account/AccountAddress/UpdateJobAddress', params)
}
//删除发单地址
export function DeleteJobAddress(params) {
    return request('post', '/api/Account/AccountAddress/DeleteJobAddress', params)
}
//设置默认发单地址
export function SetDefaultJobAddress(params) {
    return request('post', '/api/Account/AccountAddress/SetDefaultJobAddress', params)
}
//获取IP所在城市
export function getLocationCity(params) {
    return request('post', 'https://api.map.baidu.com/location/ip?ak=ia6HfFL660Bvh43exmH9LrI6')
}
// //发单方发送消息后
export function AddIMMessage(params) {
    return request('post', '/api/Im/AddIMMessage', params, false)
}
//私聊判断是否可打开投标详情
export function IsRedirectBpoApplyOrder(params) {
    return request('post', '/api/JobApplyOrder/IsRedirectBpoApplyOrder', params)
}
//获取B2B投标参数 是否到达最大投标人数|工单距离
export function GetB2bTenderPara(params) {
    return request('post', '/JobWorkflow/PickJob/GetB2bTenderPara', params)
}
//待领取优惠券列表
export function GetCouponList(params) {
    return request('post', '/api/Coupon/GetCouponList', params)
}
//用户优惠券列表
export function GetUserCouponList(params) {
    return request('post', '/api/Coupon/GetUserCouponList', params)
}
//用户获取可用和不可用优惠券的列表
export function GetUsableCouponList(params) {
    return request('post', '/api/Coupon/GetUsableCouponList', params,false)
}
//用户领取优惠券
export function DrewUserCouPons(params) {
    return request('post', '/api/Coupon/DrewUserCouPons', params)
}
//获取使用优惠券折扣后的价格
export function GetAfterDiscountsAmount(params) {
    return request('post', '/api/BpoJobPay/GetAfterDiscountsAmount', params)
}
//优惠券类型
export function GetCouponsType(params) {
    return request('post', '/api/Coupon/GetCouponsType', params)
}
//工单日历
export function GetExecDayJobList(params) {
    return request('post', '/api/JobCalendar/GetExecDayJobList', params)
}
// 工单日历
export function GetJobExecDayList(params) {
    return request('post', '/api/JobCalendar/GetJobExecDayList', params)
}
// 工单日历
export function GetAppComExecDayList(params) {
    return request('post', '/api/JobCalendar/GetAppComExecDayList', params)
}
// 工单日历
export function GetComExecDayList(params) {
    return request('post', '/api/JobCalendar/GetComExecDayList', params)
}
//获取登陆者信息
export function GetLoginAccountInfo(params) {
    return request('post', '/UserAccount/GetLoginAccountInfo', params)
}
// 提交转账凭证
export function ConfirmRechargeBank(params) {
    return request('post', '/api/Finance/ConfirmRechargeBank', params)
}
// 额度、余额信息
export function GetAccountFinanceInfo(params) {
    return request('post', '/api/Finance/GetAccountFinanceInfo', params)
}
// 余额明细列表
export function ListTradeRecordByPC(params) {
    return request('post', '/api/Finance/ListTradeRecordByPC', params)
}
// 收款账号
export function GetMyCollectionAccount(params) {
    return request('post', '/api/Finance/GetMyCollectionAccount', params)
}
// 设为默认收款账号
export function SetDefaultCollectionAccount(params) {
    return request('post', '/api/Finance/SetDefaultCollectionAccount', params)
}
// 删除收款账号
export function DeleteCollectionAccount(params) {
    return request('post', '/api/Finance/DeleteCollectionAccount', params)
}
// 添加企业收款账号
export function AddCompanyBankCardInfo(params) {
    return request('post', '/api/Finance/AddCompanyBankCardInfo', params)
}
// 添加个人收款账号
export function AddCollectionAccount(params) {
    return request('post', '/api/Finance/AddCollectionAccount', params)
}
// 确认添加银行卡账号
export function SureAddBankAccount(params) {
    return request('post', '/api/Finance/SureAddBankAccount', params)
}
// 提交个人提现申请
export function SubmitPersonWithDrawApply(params) {
    return request('post', '/api/Finance/SubmitPersonWithDrawApply', params)
}
// 提交企业提现申请
export function WithdrawWait(params) {
    return request('post', '/api/Finance/WithdrawWait', params)
}
// 提交企业提现申请
export function WeixinUnifiedorderRechargeByApp(params) {
    return request('post', '/api/BpoPay/WeixinUnifiedorderRechargeByApp', params)
}
// 首页跑马灯
export function GetIndexScrollInfo(params) {
    return request('post', '/api/BpoServiceUser/GetIndexScrollInfo', params)
}
// 首页发单配置
export function GetIndexSendJobConfig(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetIndexSendJobConfig', params)
}
// 首页弹窗配置
export function GetDialogConfig(params) {
    return request('post', '/api/BpoServiceUser/GetDialogConfig', params)
}
// 报告被拒绝
export function SetJobReportIsPromptUser(params) {
    return request('post', '/api/BpoServiceUser/SetJobReportIsPromptUser', params)
}
// 接单状态tab
export function GetPickJobStep(params) {
    return request('post', '/JobWorkflow/PickJobCenter/GetPickJobStep', params)
}
// 接单列表
export function GetPcMyPickJobList(params) {
    return request('post', '/JobWorkflow/PickJobCenter/GetPcMyPickJobList', params)
}
// 导出接单列表
export function DownPcMyPickJobList(params) {
    window.location.href = BASEURL + '/JobWorkflow/PickJobCenter/DownPcMyPickJobList?' + params;
    // return request('get', '/JobWorkflow/PickJobCenter/DownPcMyPickJobList', params)
}
// 子公司接单列表
export function GetChildComPickJobList(params) {
    return request('post', '/JobWorkflow/ChildCompanyPickJobCenter/GetChildComPickJobList', params)
}
// 导出子公司接单列表
export function DownChildComPickJobList(params) {
    window.location.href = BASEURL + '/JobWorkflow/ChildCompanyPickJobCenter/DownChildComPickJobList?' + params;
}
//我的已发工单列表
export function ListSendJob(params) {
    return request('post', '/api/Job/Job/ListSendJob', params, false);
}
//我的已发工单tab
export function GetSendJobSummary(params) {
    return request('post', '/api/Job/Job/GetSendJobSummary', params, false);
}
// 导出已发工单列表
export function DownListSendJob(params) {
    window.location.href = BASEURL + '/api/Job/Job/DownListSendJob?' + params;
}
//子公司已发工单
export function GetChildCompanySendJobList(params) {
    return request('post', '/api/Job/JobSender/GetChildCompanySendJobList', params);
}
// 导出子公司已发工单列表
export function DownChildCompanySendJobList(params) {
    window.location.href = BASEURL + '/api/Job/JobSender/DownChildCompanySendJobList?' + params;
}
//获取企业用户信息
export function GetCompanyInfo(params) {
    return request('get', '/api/AppCompany/GetCompanyInfo', params);
}
//编辑企业用户信息
export function EditCompanyInfo(params) {
    return request('post', '/api/AppCompany/EditCompanyInfo', params);
}
//编辑用户信息
export function UpdateUserInfo(params) {
    return request('post', '/api/UserCenter/UpdateUserInfo', params);
}
//重置登录密码
export function ResetPwd(params) {
    return request('post', '/api/AccountSecurity/ResetPwd', params)
}
// 修改绑定手机号
export function UpdateBindPhoneNumber(params) {
    return request('post', '/api/AccountSecurity/UpdateBindPhoneNumber', params)
}
// 修改支付密码
export function UpdatePayPwd(params) {
    return request('post', '/api/AccountSecurity/UpdatePayPwd', params)
}
//校验验证码 
export function CheckValidCode(params) {
    return request('post', '/ChuJu/HYCommon/CheckValidCode', params)
}
//工单池 
export function GetJobsInIndex(params) {
    return request('post', '/api/BpoJob/GetJobsInIndex', params)
}
//大咖面对面 
export function ListMasterVO(params) {
    return request('post', '/api/Master/ListMasterVO', params)
}
//大咖面对面详情
export function GetMasterMediaDetailVO(params) {
    return request('post', '/api/Master/GetMasterMediaDetailVO', params)
}
//获取账号绑定信息 
export function GetAccountSecurityInfo(params) {
    return request('post', '/api/AccountSecurity/GetAccountSecurityInfo', params)
}
//我的发票 
export function InvoiceableOrderView(params) {
    return request('post', '/api/Finance/InvoiceableOrderView', params)
}
//开票记录
export function InvoicehistoryByPC(params) {
    return request('post', '/api/Finance/InvoicehistoryByPC', params)
}
//申请开票
export function ReceiverConfirmInvoiceView(params) {
    return request('post', '/api/Finance/ReceiverConfirmInvoiceView', params)
}
//应付账款
export function GetListPayable(params) {
    return request('post', '/api/Payable/GetListPayable', params)
}
//应付账款-申请开票第一步
export function ApplyInvoice(params) {
    return request('post', '/api/Payable/ApplyInvoice', params)
}
//应付账款-申请开票
export function PayableSenderConfirmInvoiceCommit(params) {
    return request('post', '/api/Payable/SenderConfirmInvoiceCommit', params)
}
//应付账款-发票详情
export function GetInvoice(params) {
    return request('post', '/api/Payable/GetInvoice', params)
}
//应付账款-订单详情
export function GetOrderListByInvoiceId(params) {
    return request('post', '/api/Payable/GetOrderListByInvoiceId', params)
}
//查看开票信息
export function SenderConfirmInvoiceView(params) {
    return request('post', '/api/Finance/SenderConfirmInvoiceView', params)
}
//确认开票
export function SenderConfirmInvoiceCommit(params) {
    return request('post', '/api/Finance/SenderConfirmInvoiceCommit', params)
}
//根据发票获取订单列表
export function GetOrdersByInvoice(params) {
    return request('post', '/api/Finance/GetOrdersByInvoice', params)
}
//根据发票获取发票详情
export function InvoiceDetail(params) {
    return request('post', '/api/Finance/InvoiceDetail', params)
}
//确认开票
export function ReceiverConfirmInvoiceCommit(params) {
    return request('post', '/api/Finance/ReceiverConfirmInvoiceCommit', params)
}
//支付宝支付
export function AliConfirmRecharge(params) {
    return request('post', '/api/BpoPay/AliConfirmRecharge', params)
}
//微信支付
export function WeixinUnifiedorderByPC(params) {
    return request('post', '/api/BpoPay/WeixinUnifiedorderByPC', params)
}
//新闻类型列表
export function newsTypeList(params) {
    return request('post', ZYDAPI + '/newsApp/newsType/list', params)
}
//新闻列表
export function newsList(params) {
    return request('post', ZYDAPI + '/newsApp/news/list', params)
}
//热门新闻列表
export function hotNewsList(params) {
    return request('post', ZYDAPI + '/newsApp/news/hotNews', params)
}
//新闻浏览量累加
export function newsViewer(params) {
    return request('post', ZYDAPI + '/newsApp/news/pageView/add', params)
}
//热门新闻列表
export function newsPageViewer(params) {
    return request('post', ZYDAPI + '/newsApp/news/pageView/add', params)
}
//简历
export function resumeInfo(params) {
    return request('post', ZYDAPI + '/gz/app/resume/info/basicInfo', params)
}
//额度支付 
export function CreditPay(params) {
    return request('post', '/api/BpoJobPay/CreditPay', params)
}
//余额支付 
export function BalancePay(params) {
    return request('post', '/api/BpoJobPay/BalancePay', params)
}
//项目工单服务节点 
export function GetProjectJobServiceNoteList(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetProjectJobServiceNoteList', params)
}
//发单方项目工单结算信息 
export function GetSenderJobAmountNodeList(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetSenderJobAmountNodeList', params)
}
//项目退款 
export function JobAmountRefund(params) {
    return request('post', '/api/BpoJobPay/JobAmountRefund', params)
}
//首页广告轮播 
export function ListAdvertising(params) {
    return request('post', '/api/Advertising/ListAdvertising', params)
}
//查看报价 
export function GetJobTenderInfo(params) {
    return request('post', '/JobWorkflow/PickJob/GetJobTenderInfo', params)
}
//项目工单验收报告
export function setCheckAcceptReport(params) {
    return request('post', '/api/JobWorkflow/AddJob/CheckAcceptReport', params)
}
// 添加日常报告/验收报告
export function AddJobReport(params) {
    return request('post', '/JobWorkflow/ProjectJob/AddJobReport', params)
}
// 修改工程师费用，文件，备注
export function UpdateAmountInfo(params) {
    return request('post', '/JobWorkflow/PickJobCenter/UpdateAmountInfo', params)
}
//推荐课程
export function recommend(params) {
    return request('post', ZYDAPI + '/gz/app/course/recommend', params)
}
//APP培训首页/PC培训专区
export function indexCourse(params) {
    return request('post', ZYDAPI + '/gz/app/course/indexCourse', params)
}
//全部课程
export function allCourse(params) {
    return request('post', ZYDAPI + '/gz/app/course/allCourse', params)
}
//课程详情
export function detailCourse(params) {
    return request('post', ZYDAPI + '/gz/app/course/detail', params)
}
//发表评论
export function courseRemark(params) {
    return request('post', ZYDAPI + '/gz/app/course/add/courseRemark', params)
}
//评论点赞
export function likeRemark(params) {
    return request('post', ZYDAPI + '/gz/app/course/like/courseRemark', params)
}
//课程下拉
export function brandselect(params) {
    return request('post', ZYDAPI + '/gz/app/course/select/brand', params)
}
//产品下拉
export function productselect(params) {
    return request('post', ZYDAPI + '/gz/app/course/select/product', params)
}
//课程学习记录
export function learnAdd(params) {
    return request('post', ZYDAPI + '/gz/app/course/learn/add', params)
}
//课程购买
export function ConfirmOrderByCourse(params) {
    return request('post','/api/TrainCourse/ConfirmOrderByCourse', params)
}
// 跟踪日志
export function AddAccountTraceLog(params) {
    return request('post','/api/Trace/AddAccountTraceLog', params)
}





// ================================================================================社区论坛===============
// 获取帖子分类
export function GetPostCategory(params) {
    return request('post','/api/ForumPost/GetPostCategory', params)
}
// 获取帖子列表
export function GetPostList(params) {
    return request('post','/api/ForumPost/GetPostList', params)
}
// 帖子点赞或取消点赞
export function ForumThumbUp(params) {
    return request('post','/api/ForumPost/ForumThumbUp', params,false)
}
// 获取帖子详情
export function GetPostDetail(params) {
    return request('post','/api/ForumPost/GetPostDetail', params)
}
// 帖子收藏或取消收藏
export function ForumCollectPost(params) {
    return request('post','/api/ForumPost/ForumCollectPost', params,false)
}
// 获取帖子评论
export function GetPostCommentList(params) {
    return request('post','/api/ForumPostComment/GetPostCommentList', params)
}
// 删除帖子评论
export function DeleteComment(params) {
    return request('post','/api/ForumPostComment/DeleteComment', params)
}
// 添加评论
export function AddPostComment(params) {
    return request('post','/api/ForumPostComment/AddPostComment', params)
}
// 关注
export function FocusUser(params) {
    return request('post','/api/ForumPost/FocusUser', params, false)
}
// 获取热门帖子
export function GetHotPostList(params) {
    return request('post','/api/ForumPost/GetHotPostList', params)
}
// 举报
export function ForumComplain(params) {
    return request('post','/api/ForumPost/ForumComplain', params, false)
}
// 获取用户论坛数据信息
export function GetUserForumInfo(params) {
    return request('post','/api/ForumPost/GetUserForumInfo', params)
}
// 获取草稿箱||创作
export function GetMyPostList(params) {
    return request('post','/api/ForumUserCenter/GetMyPostList', params)
}
// 获取收藏
export function GetMyCollectPostList(params) {
    return request('post','/api/ForumUserCenter/GetMyCollectPostList', params)
}
// 获取网站的标题 或logo信息
export function GetSiteInfo(params) {
    return request('post','/api/ForumPost/GetSiteInfo', params, false)
}
// 发帖/修改帖子
export function SavePost(params) {
    return request('post','/api/ForumPost/SavePost', params)
}
// 获取视频的背景图片
export function GetVideoCoverUrl(params) {
    return request('post','/api/ForumPost/GetVideoCoverUrl', params,false)
}
// ===============================================================================END===========


// =====================================================活动===
// 获取用户论坛信息
export function GetForumSubscribeInfo(params) {
    return request('post','/api/ActivityForumSubscribe/GetForumSubscribeInfo', params,false)
}
// 获取帖子列表
export function GetForumPostList(params) {
    return request('post', ActivityUrl+'/api/ForumActivity/GetForumPostList', params,false)
}
// 获取大咖观点视频列表
export function ListServiceForumVideo(params) {
    return request('post', ActivityUrl+'/api/ForumActivity/ListServiceForumVideo', params,false)
}
// 获取赞助商列表
export function GetSponsorList(params) {
    return request('post', ActivityUrl+'/api/ForumActivity/GetSponsorList', params)
}
// 提交观点and赞助数据
export function AddServiceForumCollectInfo(params) {
    return request('post', ActivityUrl+'/api/ForumActivity/AddServiceForumCollectInfo', params)
}
// 论坛预约回调
export function DoForumSubscribeCallBack(params) {
    return request('post','/api/ActivityForumSubscribe/DoForumSubscribeCallBack', params)
}
// 添加统计数据
export function AddServiceForumStatistics(params) {
    return request('post', ActivityUrl+'/api/ForumActivity/AddServiceForumStatistics', params)
}

// =====================================================END====

// ===================================================版本4.4===
// 首页
export function GetPcHomeConfig(params) {
    return request('post', '/api/AppIndex/GetPcHomeConfig', params,false);
}
// 根据服务名称查询服务关联的查询条件数据接
export function GetConditionByName(params) {
    return request('post', ZYDAPI+'/gz/pc/reception/ChoiceServiceOffer/getConditionByName', params)
}
// 根据服务名称或条件分页查询服务数据接口
export function GetServiceByName(params) {
    return request('post', ZYDAPI+'/gz/pc/reception/ChoiceServiceOffer/getServiceByName', params)
}


// 发单
// 获取品牌
export function GetBrandList(params) {
    return request('post', '/api/Code/GetBrandList', params);
}
// 获取系列
export function GetSeriesList(params) {
    return request('post', '/api/Code/GetSeriesList', params);
}

// 获取发单之前是否有草稿
export function GetUserSendJobInfo(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetUserSendJobInfo', params);
}

// 获取发单配置
export function GetJobDisposeSet(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetJobDisposeSet', params);
}
// 提交发单第一步
export function AddJobOne(params) {
    return request('post', '/api/JobWorkflow/AddJob/AddJobOne', params);
}
//获取发单第二步特殊需求
export function GetServiceProblem(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetServiceProblem', params);
}
// 提交发单第二步
export function AddJobTwo(params) {
    return request('post', '/api/JobWorkflow/AddJob/AddJobTwo', params);
}
// 提交发单第三步
export function AddJobThree(params) {
    return request('post', '/api/JobWorkflow/AddJob/AddJobThree', params);
}

// 支付
// 支付页面支付宝支付
export function PcAliPay(params) {
    return request('post', '/api/BpoJobPay/PcAliPay', params);
}
// 支付宝支付成功页是否支付成功
export function PcAlipayNotify(params) {
    return request('post', '/api/BpoJobPay/PcAlipayNotify', params);
}

// 支付页面微信支付
export function PcWeiXinPay(params) {
    return request('post', '/api/BpoJobPay/PcWeiXinPay', params);
}
// 查询微信是否支付成功
export function WeiXinQueryOrder(params) {
    return request('post', '/api/BpoJobPay/WeiXinQueryOrder', params,false);
}

// 支付成功页获取信息
export function GetPayResultInfo(params) {
    return request('post', '/api/BpoJobPay/GetPayResultInfo', params,false);
}
// =====================================================END====


// ===========================================提现====
// 是否已签署代办和承揽协议
export function isSignRlwyContract(params) {
    return request('post', ZYDAPI+'/gz/rlwy/checkSignRlwyContract', params,false);
}
// 获取个体户工商审核状态
export function usercheck(params) {
    return request('post', ZYDAPI+'/gz/rlwy/usercheck/gov', params);
}
// ===========================================END====

//获取企业是否有待签约的b2b合同
export function GetSignContractCompanyInfo(params) {
    return request('post','/api/BpoContract/GetSignContractCompanyInfo', params);
}
//h5协议签b2b协议
export function H5SignB2bContract(params) {
    return request('post','/api/BpoContract/H5SignB2bContract', params);
}

//选标不支付
export function ChooseApplyOrder(params) {
    return request('post','/api/JobWorkflow/AddJob/ChooseApplyOrder', params);
}
//获取道具配置
export function GetPropConfig(params) {
    return request('post','/api/appindex/GetPropConfig', params);
}
 //添加联系记录
export function AddUserContractLog(params) {
    return request('post','/api/JobWorkflow/AddJob/AddUserContractLog', params,false);
}
  //内容审核
export function TextScan(params) {
    return request('post','/api/AppIndex/TextScan', params,false);
}
 
// 获取会员中心信息展示数据
export function GetMemberCenterInfo(params) {
    return request('post', '/api/Account/AccountMember/GetMemberCenterInfo', params);
}

// 创建会员申请订单 - 点击非连续会员按钮获取支付ID
export function CreateMemberApplyOrder(params) {
    return request('post', '/api/Account/AccountMember/CreateMemberApplyOrder', params);
}

// 点击连续会员 - pc扫码支付订单查询，用于查询状态，然后跳转页面
export function AlipayQueryOrder(params) {
    return request('post', '/api/BpoJobPay/AlipayQueryOrder', params,false);
}

//抽奖接口（九宫格）
export function JobDrawPrize(params) {
    return request('post', '/JobWorkflow/AddJobCenter/JobDrawPrize', params);
}
//获取列表选项接口（九宫格）
export function GetAppSendJobViewInfo(params) {
    return request('post', '/api/JobWorkflow/AddJob/GetAppSendJobViewInfo', params);
}
//获取列表选项接口（H5 九宫格）
export function GetPrizeMultipleList(params) {
    return request('post', '/JobWorkflow/AddJobCenter/GetPrizeMultipleList', params);
}